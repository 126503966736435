import React, { useEffect, useMemo } from "react";

import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetApplicationFiltersQuery } from "store/slices/commentSlice";

const getOptions = (comments, key) => {
  const options = comments.map((item) => item[key]);
  return [...new Set(options)];
};

const getValues = (params, name) => {
  return params.get(name)?.split("_") || [];
};

const OverallSummaryFilter = ({ comments }) => {
  const { data: appFilters, isLoading: isFilterLoading } = useGetApplicationFiltersQuery({ page_name: "glint_comments" });
  const [currentParams, setCurrentParams] = useSearchParams();
  const dateFilterOptions = appFilters?.glint_comments?.survey_creation_date ? Object.values(appFilters.glint_comments.survey_creation_date) : [];
  const searchParam = currentParams.get("search");
  const date = currentParams.get("dates");

  useEffect(() => {
    if(dateFilterOptions.length > 0 && !date) {
      currentParams.set("dates", dateFilterOptions.at(-1));
      setCurrentParams(currentParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterOptions, date]);

  const filters = useMemo(() => {
    return [
      {
        label: "Division",
        name: "division",
        options: getOptions(comments, "division"),
        value: getValues(currentParams, "division"),
      },
      {
        label: "Management Level",
        name: "mgmtLvl",
        options: getOptions(comments, "management_level"),
        value: getValues(currentParams, "mgmtLvl"),
      },
      {
        label: "Manager L2",
        name: "l2m",
        options: getOptions(comments, "l2_sup_org"),
        value: getValues(currentParams, "l2m"),
      },
      {
        label: "Manager L3",
        name: "l3m",
        options: getOptions(comments, "l3_sup_org"),
        value: getValues(currentParams, "l3m"),
      },
      {
        label: "Geo",
        name: "geo",
        options: getOptions(comments, "geo"),
        value: getValues(currentParams, "geo"),
      },
      {
        label: "Country",
        name: "country",
        options: getOptions(comments, "country"),
        value: getValues(currentParams, "country"),
      },
      {
        label: "Favorability",
        name: "favorability",
        options: getOptions(comments, "favorability"),
        value: getValues(currentParams, "favorability"),
      },
      {
        label: "Question",
        name: "question",
        options: getOptions(comments, "question"),
        value: getValues(currentParams, "question"),
      },
    ];
  }, [comments, currentParams]);
  
  const handleChange = (name, values) => {
    if(values.length === 0) {
      currentParams.delete(name);
    } else {
      currentParams.set(name, values.join("_"));
    }
    setCurrentParams(currentParams);
  };

  const handleClearAllFilters = () => {
    const params = new URLSearchParams();
    params.set("search", searchParam);
    setCurrentParams(params);
  };

  const handleChangeDate = (e, newValue) => {
    currentParams.set("dates", newValue);
    setCurrentParams(currentParams);
  };

  if(isFilterLoading) {
    return <Box className="text-center" py={8}>
      <BeatLoader color="#5C5470" />
    </Box>;
  }
  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
        <Grid item md={4}>
          <Autocomplete
            options={dateFilterOptions.sort()}
            getOptionLabel={(option) => dayjs(option).format("MMM 'YY")}
            size="small"
            onChange={handleChangeDate}
            value={date}
            ChipProps={{
              variant: "contained",
              color: "primary",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Dates"
                placeholder="Dates"
              />
            )}
          />
        </Grid>
        {filters.map((filter) => (
          <Grid item key={filter.name} md={4}>
            <Autocomplete
              multiple
              options={filter.options.sort()}
              getOptionLabel={(option) => option}
              value={filter.value}
              size="small"
              onChange={(_e, values)=>handleChange(filter.name, values)}
              ChipProps={{
                variant: "contained",
                color: "primary",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={filter.label}
                  placeholder={filter.label}
                />
              )}
            />
          </Grid>
        ))}
        <Grid item md={12}>
          <Box className="flex justify-center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClearAllFilters}
              size="small"
            >
              Clear Filters
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default OverallSummaryFilter;
