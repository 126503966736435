import React, { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useLazyGetInsightsGenieDataQuery } from "store/slices/insightsGenieSlice";

const DownloadExcel = () => {
  const [currentParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [getExcelDownloadUrl] = useLazyGetInsightsGenieDataQuery();
  const domain = currentParams.get("domain");
  const division = currentParams.get("division");
  const dates = currentParams.get("dates")?.split(",") || [];
  const l2_org = currentParams.get("l2_org");
  const l3_org = currentParams.get("l3_org");
  const l4_org = currentParams.get("l4_org");

  const handleDownload = async () => {  
    try {
      setIsLoading(true);
      if(!domain || !division) {
        setIsLoading(false);
        return;
      }
      const url = await getExcelDownloadUrl({
        division,
        domain,
        dates,
        l2_org,
        l3_org,
        l4_org
      }).unwrap();
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        url
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      setIsLoading(false);
    } catch (error) {
      console.error({ error });
      setIsLoading(false);
    }
  };

  return (
    <Box mt={2}>
      <LoadingButton 
        disabled={!domain || !division || !dates.length} 
        variant="outlined" 
        onClick={handleDownload} 
        loading={isLoading}
      >
        Generate Insights
      </LoadingButton>
    </Box>
  );
};

export default DownloadExcel;
