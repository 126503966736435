import { createTheme } from "@mui/material/styles";

import { MuiButton } from "./button";
import { palette } from "./palette";
import { typography } from "./typography";

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton,
  },
});
