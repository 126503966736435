import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CheckboxFormField from "components/FormComponents/CheckboxFormField";
import TextFormField from "components/FormComponents/TextFormField";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";

import PermissionCheckList from "../PermissionCheckList/PermissionCheckList";

const UserEditor = ({ open, onClose, user, availableTreeValues, onUpdate, onCreate }) => {  
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const { handleSubmit, control, register, formState: { errors, isValid } } = useForm({
    values: {
      personas: user?.personas || "",
      comments: user?.comments || "",
      name: user?.name || "",
      override_permissions: user?.override_permissions || {},
      is_active: user?.is_active || true,
      is_admin: user?.is_admin || false
    },
    mode: "all"
  });

  useEffect(() => {
    if(!user) {
      register("email_address", "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      if(user) {
        await onUpdate({ data: values, email_address: user.email_address });
      } else {
        await onCreate({ data: values });
      }
      onClose(); 
    } catch (error) {
      console.error({ error });
      if(error.status === 400) {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>User Editor</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item md={12}>
              <TextFormField
                name="name"
                label="Name"
                type="text"
                fullWidth
                control={control}
                error={errors.name}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            {!user && (
              <Grid item md={12}>
                <TextFormField
                  name="email_address"
                  label="Email"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.email_address}
                  rules={{
                    required: "Email is required"
                  }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <TextFormField
                name="comments"
                label="Comments (Optional)"
                type="text"
                fullWidth
                control={control}
                error={errors.comments}
                multiline
                minRows={8}
              />
            </Grid>
            <Grid item md={12} display="flex">
              <Box mr={8}>
                <CheckboxFormField 
                  control={control}
                  name="is_active"
                  label="Active"
                />
              </Box>
              <Box>
                <CheckboxFormField 
                  control={control}
                  name="is_admin"
                  label="Admin"
                />
              </Box>
            </Grid>
          </Grid>
          {availableTreeValues && (
            <>
              <Typography variant="body1" mb={1} mt={4}>Permissions</Typography>
              <Controller 
                control={control}
                name="override_permissions"
                render={({ field }) => (
                  <PermissionCheckList 
                    value={field.value}
                    treeStructure={availableTreeValues} 
                    onChange={(value) => field.onChange(value)}
                    blockedValues={user?.persona_permissions || []}
                  />
                )}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button type="button" onClick={onClose}>Cancel</Button>
          <LoadingButton color="primary" variant="contained" type="submit" disabled={!isValid} loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserEditor;
