import React from "react";

import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";

import FavorabilityOverTime from "./FavorabilityOverTime";
import SearchTermByQuestion from "./SearchTermByQuestion";
import SearchTermResultsOverTime from "./SearchTermResultsOverTime";

const getValues = (params, name) => {
  return params.get(name)?.split("_") || [];
};

const Chart = ({ filteredComments, isLoading=false, isSearchTopic }) => {
  const { data: comments } = useSelector((state) => state.comment);
  const [currentParams] = useSearchParams();

  const country = getValues(currentParams, "country");
  const favorability = getValues(currentParams, "favorability");
  const division = getValues(currentParams, "division");
  const l2m = getValues(currentParams, "l2m");
  const l3m = getValues(currentParams, "l3m");
  const geo = getValues(currentParams, "geo");
  const mgmtLvl = getValues(currentParams, "mgmtLvl");

  if (isLoading) {
    return (
      <div className="text-center">
        <BeatLoader size={15} color="#5C5470" />
      </div>
    );
  }

  const filteredCommentsNoDates = comments.filter(
    (item) =>
      (!country.length || country.includes(item.country)) &&
      (!favorability.length || favorability.includes(item.favorability)) &&
      (!division.length || division.includes(item.division)) &&
      (!l2m.length || l2m.includes(item.l2_sup_org)) &&
      (!l3m.length || l3m.includes(item.l3_sup_org)) &&
      (!geo.length || geo.includes(item.geo)) &&
      (!mgmtLvl.length || mgmtLvl.includes(item.management_level))
  );

  return (
    <Grid container spacing={4}>
      {!isSearchTopic ? (
        <>
          <Grid item xs={12} md={3}>
          </Grid>
          <Grid item xs={12} md={6}>
            <SearchTermByQuestion filteredComments={filteredComments} />
          </Grid>
          <Grid item xs={12} md={3}>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={4}>
            <SearchTermByQuestion filteredComments={filteredComments} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchTermResultsOverTime data={filteredCommentsNoDates} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FavorabilityOverTime data={filteredCommentsNoDates} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Chart;
