export const columns = [
  { 
    accessorKey: "geo",
    header: "Geo",
  },
  { 
    accessorKey: "country",
    header: "Country",
  },
  { 
    accessorKey: "city", 
    header: "City", 
  },
  {
    accessorKey: "rel_cost_labor",
    header: "Relative Cost of Labor",
  },
  {
    accessorKey: "supply",
    header: "Talent Supply",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    accessorKey: "demand",
    header: "Talent Demand",
    muiTableBodyCellProps: {
      align: "right",
    },
  },
  {
    accessorKey: "supplyDemandRatio",
    header: "Supply/Demand Ratio",
  },
  {
    accessorKey: "competitiveConcentration",
    header: "Competitive Concentration",
  },
  {
    accessorKey: "postingDuration",
    header: "Time to Hire",
  },
  {
    accessorKey: "hiringDifficulty",
    header: "Hiring Difficulty",
  },
];
