import React from "react";

import { Grid } from "@mui/material";

import AIGeneratedComments from "./AIGeneratedComents/AIGeneratedComments";
import FilteredComments from "./FilteredComments";
const CommentList = ({ filteredComments }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <AIGeneratedComments filteredComments={filteredComments} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FilteredComments filteredComments={filteredComments} />
      </Grid>
    </Grid>
  );
};

export default CommentList;
