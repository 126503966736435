import React, { useEffect, useRef, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { titleize } from "utils/slug";

const BlogDetailView = ({ blog }) => {
  const [showReadMore, setShowReadMore] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const elementRef = useRef(null);

  // checking the element height
  function elementCheck() {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight;
      if (height > window.innerHeight) {
        setShowReadMore(true);
      }
    }
  }

  useEffect(() => {
    if (blog) {
      elementCheck();
      window.scrollTo(0, 0);
    }
  }, [blog]);

  if (!blog) {
    return null;
  }

  return (
    <section ref={elementRef}>
      <nav
        className="text-black ml-8 text-lg text-left font-bold my-8"
        aria-label="Breadcrumb"
      >
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to={"/"}>Home</Link>
            <ChevronRightIcon />
          </li>
          <li className="flex items-center">
            <Link to={`/${window.location.href.split("/")[3]}`}>
              {titleize(window.location.href.split("/")[3])}
            </Link>
            <ChevronRightIcon />
          </li>
          <li>
            <Link to={"#"} className="text-gray-500" aria-current="page">
              {blog.title}
            </Link>
          </li>
        </ol>
      </nav>

      <div className="max-w-screen-xl mx-auto">
        <main className="mt-10">
          <div
            className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative"
            style={{ height: "24em" }}
          >
            <div
              className="absolute left-0 bottom-0 w-full h-full z-10"
              style={{
                backgroundImage: "linear-gradient(180deg,transparent,rgba(0,0,0,.7))",
              }}
            ></div>
            <img
              src={blog.image}
              className="absolute left-0 top-0 w-full h-full z-0 object-cover"
            />
            <div className="p-4 absolute w-full bottom-0 left-50 right-50 transform translateX( -50%) z-20 ">
              <a
                href="#"
                className="px-4 py-1 text-center bg-black text-gray-200 inline-flex items-center justify-center mb-2"
              >
                {Array.isArray(blog.category)
                  ? blog.category.join(" & ")
                  : blog.category}
              </a>
              <h2 className="text-center text-4xl font-semibold text-gray-100 leading-tight">
                {blog.title}
              </h2>
            </div>
          </div>

          <div className="px-4 lg:px-0 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed">
            <div className="text-left pt-5">
              <p className="text-lg">{blog.description}</p>
            </div>
            <hr />
            <div className="flex flex-col">
              <div className="px-4 lg:px-0  text-gray-700 text-lg leading-relaxed w-full ">
                <Box className="general-content pb-6 text-lg text-left">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blog.content,
                    }}
                    style={{
                      height:
                        showReadMore && !isOpen
                          ? "calc(100vh - 700px)"
                          : "100%",
                      overflow: showReadMore && !isOpen ? "hidden" : "unset",
                    }}
                  ></div>
                  {showReadMore && !isOpen && (
                    <div className="flex justify-center items-center pt-10">
                      <div
                        onClick={() => setIsOpen(true)}
                        className="b mb-5 mx-auto h-14 w-64 flex justify-center items-center cursor-pointer"
                      >
                        <div className=" h-14 w-64 bg-gradient-to-br from-[#3B71FF] to-[#C764F7] items-center rounded-full shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out"></div>
                        <a className="text-center text-white font-semibold z-10 pointer-events-none">
                          Continue Reading
                        </a>
                      </div>
                    </div>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </main>
      </div>
    </section>
  );
};

export default BlogDetailView;
