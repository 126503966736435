import React from "react";

import { Alert, Box, Card, CardContent, Grid } from "@mui/material";
import DateRange from "pages/Dashboards/InsightsGenie/DateRange/DateRange";
import DomainSelect from "pages/Dashboards/InsightsGenie/DomainSelect/DomainSelect";
import DownloadExcel from "pages/Dashboards/InsightsGenie/DownloadExcel/DownloadExcel";
import SelectOptions from "pages/Dashboards/InsightsGenie/SelectOptions/SelectOptions";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetInsightsGenieQuery } from "store/slices/insightsGenieSlice";

const InsightGenie = () => {
  const { data, isLoading } = useGetInsightsGenieQuery();
  const [currentParams] = useSearchParams();
  const domain = currentParams.get("domain");
  const dateData = data?.[domain];

  if (isLoading) {
    return (
      <Box className="flex justify-center items-center" py={8} height="calc(100vh - 148px)">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!data) {
    return null;
  }
  
  return (
    <Box height="calc(100vh - 200px)">
      <Card sx={{ border: "1px solid black", mt:8 }}>
        <CardContent sx={{ p: 4 }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <SelectOptions data={data} />
            </Grid>
            <Grid item md={12}>
              <DomainSelect domains={data.domains} />
            </Grid>
            {dateData ? (
              <Grid item md={6}>
                <DateRange data={dateData} />
              </Grid>
            ) : (
              domain && 
              <Alert sx={{ width: "100%", mx: 2 }} severity="warning">Coming Soon.. </Alert>
            )}
          </Grid>
          <DownloadExcel />
        </CardContent>
      </Card>
    </Box>
  );
};

export default InsightGenie;
