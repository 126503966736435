
import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";



const DemandTrend = ({ data }) => {
  const chartData = data ? data.map((item) => ({
    period: item.period,
    demandCount: +item.demandCount
  })) : null;

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" my={4}>
        Demand Trend
      </Typography>
      <DemandTrendChart
        id="demand-trend"
        data={chartData}
        dataKeys={[
          {
            name: "Demand Count",
            yKey: "demandCount"
          }
        ]}
        categoryKey="period"
      />
    </Box>
  );
};

export default DemandTrend;

const DemandTrendChart = ({ data, dataKeys=[], id, height="400px", categoryKey="category", unit }) => {

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);

    root.setThemes([MyTheme.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout
      })
    );

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: unit ? `# '${unit}'`: "#",
        extraMax: 0.05
      })
    );

    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: true,
          minGridDistance: 20
        }),
        categoryField: categoryKey,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    xAxis.data.setAll(data);

    function makeSeries(name, yValueField, xCategoryField, countKey) {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name || "Series",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: yValueField,
          categoryXField: xCategoryField,
          tooltip: am5.Tooltip.new(root, {
            labelText: 
              countKey ? 
                unit ? `{name}: {valueY}${unit}\nCount: {${countKey}}`
                  : `{name}: {valueY}\nCount: {${countKey}}`
                :unit ? `{name}: {valueY}${unit}`
                  : "{name}: {valueY}"
          }),
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueY}",
        width: am5.percent(50),
        tooltipY: 0,
      });
      
      if(countKey) {
        series.bullets.push(function() {
          return am5.Bullet.new(root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(root, {
              text: `{${countKey}}`,
              centerX: am5.percent(50),
              centerY: am5.percent(80),
              // textAlign: "center",
              populateText: true
            })
          });
        });
      }


      series.data.setAll(data);
      series.appear();

      legend.data.push(series);
    }

    dataKeys.forEach(({ name, yKey, countKey }) => {
      makeSeries(name, yKey, categoryKey, countKey);
    });

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};

