import React from "react";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AdminLanding = () => {
  const pages = useSelector((state) => state.currentUser.pages);
  
  const tabs = [
    ...(pages?.includes("dashboards") 
      ? [
        { 
          label: "Dashboard Management", 
          outlinedIcon: <DashboardOutlinedIcon sx={{ fontSize: "80px" }} />,
          to: "/admin/dashboard-management" 
        }
      ] : []),
    
    ...(pages?.includes("research")
      ? [
        {
          label: "Research Management",
          outlinedIcon: <BiotechOutlinedIcon sx={{ fontSize: "80px" }} />,
          to: "/admin/research-management",
        },
      ]: []),
    
    ...(pages?.includes("blogs")
      ? [
        {
          label: "Blog Management",
          outlinedIcon: <StickyNote2OutlinedIcon sx={{ fontSize: "80px" }} />,
          to: "/admin/blog-management",
        },
      ]: []),
    
    ...(pages?.includes("listening")
      ? [
        {
          label: "Employee Listening Management",
          outlinedIcon: <RecordVoiceOverOutlinedIcon sx={{ fontSize: "80px" }} />,
          to: "/admin/employee-listening-management",
        },
      ]: []),
    {
      label: "Permission Management",
      outlinedIcon: <ManageAccountsOutlinedIcon sx={{ fontSize: "80px" }} />,
      to: "/admin/permission-management",
    },
  ];
  return (
    <Grid container spacing={8} mt={4} mb={8}>
      {tabs.map((tab) => (
        <Grid item md={4} xs={12} key={tab.label}>
          <Link
            to={tab.to}
            className="flex gap-2 items-center text-indigo-400 text-lg group-hover:text-slate-800 transition duration-200"
          >
            <Card key={tab.label} className="w-full h-[300px] hover:shadow-xl" sx={{ color: "rgba(0, 0, 0, 0.6)", "&: hover": { color: "#374151" } }}>
              <CardContent className="flex justify-center items-center h-full">
                <Box>
                  <Box className="text-center">
                    {tab.outlinedIcon}
                  </Box>
                  <Box mt={1} textAlign="center">
                    <ArrowRightAltIcon sx={{ fontSize: "40px" }} />
                  </Box>
                  <Typography variant="h5" className="text-center">{tab.label}</Typography> 
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminLanding;
