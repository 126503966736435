import * as am5 from "@amcharts/amcharts5";

export class MyTheme extends am5.Theme {
  setupDefaultRules() {
    
    this.rule("ColorSet").setAll({
      colors: [
        am5.color(0x186bff),
        am5.color(0xa90c7c),
        am5.color(0xe51050),
        am5.color(0xff6b00),
        am5.color(0xdbae03),
        am5.color(0xa69e84),
        am5.color(0x88A943),
        am5.color(0x038c4a),
        am5.color(0x37a5cc),
      ],
      reuse: true
    });
  }
}
