import React from "react";

import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGetLocationFiltersQuery } from "store/slices/locationSlice";

const JobFunctionFilter = () => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const { data: filterData } = useGetLocationFiltersQuery();
  const filterOptions = filterData?.["location-insights-internal"] || [];
  
  const job = currentParams.get("job");

  const handleChangeJob = (value) => {
    if(!value) {
      currentParams.delete("job");
    } else {
      currentParams.set("job", value);
    }
    setCurrentParams(currentParams);
  };

  const jobOptions = filterOptions?.job_function || [];


  return (
    <>
      <Typography variant="body1" gutterBottom>Job Function</Typography>
      <FormControl size="small" sx={{ minWidth: 160 }}>
        <Autocomplete
          value={job}
          onChange={(_e, values)=>handleChangeJob(values)}
          size="small"
          ChipProps={{
            variant: "contained",
            color: "primary",
          }}
          options={[...jobOptions]?.sort((a, b) => a.localeCompare(b)) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "400px" }}
              variant="outlined"
              placeholder="Job Function"
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default JobFunctionFilter;
