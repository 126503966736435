import React from "react";

import { Link } from "react-router-dom";

import styles from "./DashboardTile.module.scss";

const DashboardTile = ({ comingSoon, link, imgSrc, description, title }) => {
  return (
    <Link
      className={`${styles.tile} ${comingSoon ? styles.coming_soon : ""}`}
      to={comingSoon ? "#" : `/dashboard/${link}`}
    >
      <div className={styles.image_container}>
        <img src={imgSrc} alt={title} />
      </div>
      <div className="mt-5">
        <h4 className="text-left">{title}</h4>
        {comingSoon && <p className="text-center">In Development</p>}
        <p className="text-left text-[8px]">
          {description?.substring(0, 250)}
          {description.length > 250 ? "..." : ""}
        </p>
      </div>
    </Link>
  );
};

export default DashboardTile;
