import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const researchSlice = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getImageUploadUrl: builder.query({
      query: () => ({
        url: "/secure-url"
      }),
    }),
    uploadImage: builder.mutation({
      query: ({ data, url }) => ({
        preferredBaseUrl: url,
        data,
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }),
    }),
    getAllResearches: builder.query({
      query: () => ({
        url: "/researchTiles",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
      providesTags: ["Researches"]
    }),
    getResearch: builder.query({
      query: ({ id }) => ({
        url: "/researchTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        }
      }),
    }),
    postResearch: builder.mutation({
      query: ({ ext, data }) => ({
        url: "/researchTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          ext
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["Researches"]
    }),
    updateResearch: builder.mutation({
      query: ({ id, ext, data }) => ({
        url: "/researchTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
          ext
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["Researches"]
    }),
    deleteResearch: builder.mutation({
      query: ({ id }) => ({
        url: "/researchTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
        },
        method: "DELETE"
      }),
      invalidatesTags: ["Researches"]
    }),
    getAllUpcomingResearches: builder.query({
      query: () => ({
        url: "/upcoming-research",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
      providesTags: ["UpcomingResearches"]
    }),
    createUpcomingResearch: builder.mutation({
      query: ({ data }) => ({
        url: "/upcoming-research",
        params: {
          token: Cookies.get("storedAccessToken")
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["UpcomingResearches"]
    }),
    updateUpcomingResearch: builder.mutation({
      query: ({ id, data }) => ({
        url: "/upcoming-research",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["UpcomingResearches"]
    }),
    deleteUpcomingResearch: builder.mutation({
      query: ({ id }) => ({
        url: "/upcoming-research",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
        method: "DELETE"
      }),
      invalidatesTags: ["UpcomingResearches"]
    }),
  }),
});

export const userSlice = createSlice({
  name: "researchTiles",
  initialState,
});

export const { 
  useUploadImageMutation, 
  useUpdateResearchMutation, 
  useLazyGetImageUploadUrlQuery, 
  usePostResearchMutation, 
  useDeleteResearchMutation,
  useGetAllResearchesQuery,
  useGetResearchQuery,
  useLazyGetResearchQuery,
  useGetAllUpcomingResearchesQuery,
  useCreateUpcomingResearchMutation,
  useUpdateUpcomingResearchMutation,
  useDeleteUpcomingResearchMutation,
} = researchSlice;
