import React from "react";

import { Box, Typography } from "@mui/material";

const AttritionSummary = ({
  locationAttrition,
  teamAttrition
}) => {
  return (
    <Box>
      <>
        <Typography variant="h4" fontWeight="bold" mb={4}>
        Team Attrition
        </Typography>
        <Attrition data={locationAttrition} />
      </>
      <>
        <Typography variant="h4" fontWeight="bold" mt={8} mb={4}>
        Autodesk-level Attrition
        </Typography>
        <Attrition data={teamAttrition} />
      </>
    </Box>
  );
};

export default AttritionSummary;

const Attrition = ({ data }) => {
  if(!data) {
    return (
      <Typography variant="body1">No data available</Typography>
    );
  }
  const voluntary = data.voluntary_attrition_rate;
  const inVoluntary = data.involuntary_attrition_rate;
  const total = data.total_attrition_rate;

  return (
    <Box display="flex">
      <Box width="240px" display="flex" justifyContent="center" flexDirection="column" border="1px solid grey" mr={2}>
        <Typography variant="h1" textAlign="center" fontSize="32px" fontWeight="bold">
          {(Math.round(total * 10) / 10).toFixed(1)}%
        </Typography>
        <Typography variant="h6" textAlign="center" fontWeight="bold">
          Overall Attrition Rate for the past 12 months
        </Typography>
      </Box>
      <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between" my={-1}>
        <Box border="1px solid grey" my={1} p={2} textAlign="center">
          <Typography variant="body1" fontSize="24px" fontWeight="bold">
            {(Math.round(voluntary * 10) / 10).toFixed(1)}%
          </Typography>
          <Typography variant="body1" fontSize="16px">
            Voluntary Attrition
          </Typography>
        </Box>
        <Box border="1px solid grey" my={1} p={2} textAlign="center">
          <Typography variant="body1" fontSize="24px" fontWeight="bold">
            {(Math.round(inVoluntary * 10) / 10).toFixed(1)}%
          </Typography>
          <Typography variant="body1" fontSize="16px">
            Involuntary Attrition
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
