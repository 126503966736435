import React from "react";

import { FormControlLabel, Checkbox, Box, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

const CheckboxFormField = ({ control, label, name, rules, error }) => {
  return (
    <Box
    >
      <FormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                checked={props.value}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            )}
          />
        }
        label={label}
      />
      {error && (
        <FormHelperText>{error.message}</FormHelperText>
      )}
    </Box>
  );
};

export default CheckboxFormField;
