import React from "react";

import { FormControlLabel, FormControl, FormLabel, RadioGroup, Radio } from "@mui/material";
import { Controller } from "react-hook-form";

const RadioFormField = ({
  control,
  name,
  rules,
  options = [],
  isRow,
  label
}) => {
  return (
    <FormControl>
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field} sx={{ flexDirection: isRow ? "row" : "column" }}>
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export default RadioFormField;
