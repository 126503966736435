import React, { useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetAllUpcomingResearchesQuery } from "store/slices/researchSlice";

const Upcoming = () => {
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetAllUpcomingResearchesQuery();
  const tableData = data?.data || [];

  const columns = [
    {
      field: "research",
      headerName: "Research",
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      nowrap: false,
      type: "text",
      width: 350,
    },
    {
      field: "research_type",
      headerName: "Research Type",
      width: 100,
    },
    {
      field: "lead",
      headerName: "Research Lead",
    },
    {
      field: "timeline",
      headerName: "Timeline",
      //   type: 'number',
      width: 100,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <div
          onClick={() => setOpen(true)}
          className="hover:text-sky-800 cursor-pointer"
        >
          Upcoming Research
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" fontSize="18px" fontWeight="bold">
          Upcoming Researches
        </DialogTitle>
        <DialogContent>
          <DataGrid
            rows={tableData}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
          Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Upcoming;
