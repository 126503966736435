import React, { useState } from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useGetAllUpcomingResearchesQuery, useDeleteUpcomingResearchMutation, useUpdateUpcomingResearchMutation, useCreateUpcomingResearchMutation } from "store/slices/researchSlice";
import Swal from "sweetalert2";

import UpcomingResearchEditor from "./UpcomingResearchEditor";
import UpcomingResearchFilter from "./UpcomingResearchFilter";

const UpcomingResearchManagement = () => {
  const [searchKey, setSearchKey] = useState("");

  const [selectedUpcomingResearch, setSelectedUpcomingResearch] = useState(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteUpcomingResearch] = useDeleteUpcomingResearchMutation();
  const [updateUpcomingResearch] = useUpdateUpcomingResearchMutation();
  const [createUpcomingResearch] = useCreateUpcomingResearchMutation();

  const { data, isLoading } = useGetAllUpcomingResearchesQuery();
  const upcomingResearches = data?.data || [];
  const researchTypeFilter = data?.research_type_filter || [];

  const searchFilteredUpcomingResearches = upcomingResearches.filter(upcomingResearch => upcomingResearch.research.toLowerCase().includes(searchKey.toLowerCase()));

  const handleEdit = async (row) => {
    try {
      setSelectedUpcomingResearch(row);
      setOpen(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleRemove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this upcoming research?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await deleteUpcomingResearch({ id: id }).unwrap();
      enqueueSnackbar("Upcoming Research has been deleted.", { variant: "success" });
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => handleRemove(params.row.id)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "research",
      headerName: "Research",
      width: 200,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      width: 300,
      renderCell: ({ row }) => (
        <Box className="line-clamp-3">{row.description}</Box>
      )
    },
    {
      field: "lead",
      headerName: "Lead",
      width: 100,
      editable: true,
    },
    {
      field: "research_type",
      headerName: "Research Type",
      width: 150,
      editable: true,
    },
    {
      field: "timeline",
      headerName: "Timeline",
      width: 100,
      editable: true,
    },
  ];

  const handleCreateNew = () => {
    setSelectedUpcomingResearch(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUpcomingResearch(null);
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleCreate = async (values) => {
    return await createUpcomingResearch(values).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updateUpcomingResearch(values).unwrap();
  };

  return (
    <>

      <div>
        {/* Filter */}
        <UpcomingResearchFilter
          onCreateNew={handleCreateNew}
          searchKey={searchKey}
          onSearch={handleSearch}
        />
      </div>
      {/* Data Grid */}
      <Box sx={{ height: 800, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rows={searchFilteredUpcomingResearches}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          loading={isLoading}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
        />
      </Box>
      <UpcomingResearchEditor
        open={open}
        upcomingResearch={selectedUpcomingResearch}
        onClose={handleClose}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        researchTypeFilter={researchTypeFilter}
      />
    </>
  );
};

export default UpcomingResearchManagement;
