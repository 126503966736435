import React from "react";

import { Grid } from "@mui/material";

import JobTitleFilter from "./JobTitleFilter";
import LocationFilter from "./LocationFilter";

const Filters = () => {


  return (
    <Grid container spacing={4}>
      <Grid item xl={6}>
        <LocationFilter />
      </Grid>
      <Grid item xs={6}>
        <JobTitleFilter />
      </Grid>
    </Grid>
  );
};

export default Filters;
