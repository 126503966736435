import React from "react";

import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";

const CollapsedContent = ({ themes }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {Object.entries(themes["Themes"]).map(([title, description]) => (
            <TableRow
              key={title}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Box mt={3} mb={1}>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>{title}</Typography>
                  <Typography variant="body1">{description}</Typography>
                </Box>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsedContent;
