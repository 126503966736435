import React, { useState } from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useDeleteResearchMutation, useGetAllResearchesQuery, useLazyGetResearchQuery, usePostResearchMutation, useUpdateResearchMutation } from "store/slices/researchSlice";
import Swal from "sweetalert2";

import ResearchEditor from "./CurrentResearchEditor";
import ResearchFilter from "./CurrentResearchFilter";

const CurrentResearchManagement = () => {
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState("all");

  const [selectedResearch, setSelectedResearch] = useState(null);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteResearch] = useDeleteResearchMutation();
  const [updateResearch] = useUpdateResearchMutation();
  const [createResearch] = usePostResearchMutation();
  const [getResearch] = useLazyGetResearchQuery();

  const { data: researches = [], isLoading } = useGetAllResearchesQuery();

  const searchedResearches = researches.filter(research => research.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredResearches = dateFilter !== "all" ? searchedResearches.filter(research => dayjs(research.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedResearches;

  const allCategories = researches ? researches.map(item => item.category) : [];
  const categories = [...new Set(allCategories.flat())];

  const handleEdit = async (row) => {
    try {
      const research = await getResearch({ id: row.id }).unwrap();
      setSelectedResearch(research);
      setOpen(true);
    } catch (error) {
      console.log({ error });
    }
  };

  const handleRemove = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Research",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      await deleteResearch({ id: id }).unwrap();
      enqueueSnackbar("Research has been deleted.", { variant: "success" });
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => handleRemove(params.row.id)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      editable: true,
    },
    {
      field: "description",
      headerName: "Description",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 300,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "date",
      headerName: "Year",
      width: 100,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category",
      // type: "number",
      width: 150,
      editable: true,
    },
  ];

  const handleCreateNew = () => {
    setSelectedResearch(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedResearch(null);
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const handleCreate = async (values) => {
    return await createResearch(values).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updateResearch(values).unwrap();
  };

  return (
    <>

      <div>
        {/* Filter */}
        <ResearchFilter
          onCreateNew={handleCreateNew}
          searchKey={searchKey}
          onSearch={handleSearch}
          dateFilter={dateFilter}
          onDateFilterChange={handleDateFilterChange}
        />
      </div>
      {/* Data Grid */}
      <Box sx={{ height: 800, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rows={searchFilteredResearches}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}

          loading={isLoading}
          pageSizeOptions={[5]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
      <ResearchEditor
        categories={categories}
        open={open}
        research={selectedResearch}
        onClose={handleClose}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
      />
    </>
  );
};

export default CurrentResearchManagement;
