import React, { useState } from "react";

import { Typography, Box, Pagination } from "@mui/material";
import CardListFilter from "components/CardListFilter/CardListFilter";
import dayjs from "dayjs";
import BeatLoader from "react-spinners/BeatLoader";
import { useGetAllResearchesQuery } from "store/slices/researchSlice";
import { getCurrentPageData, getPageCount } from "utils/pagination";

import ResearchCard from "./ResearchCard";
import TopLink from "../TopLink/TopLink";

const ITEM_PER_PAGE = 6;

const Research = () => {
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [page, setPage] = useState(1);

  const { data: researches = [], isLoading } = useGetAllResearchesQuery();

  const searchedResearches = researches.filter(research => research.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredResearches = dateFilter ? searchedResearches.filter(research => dayjs(research.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedResearches;
  const filterResult = categoryFilter ? searchFilteredResearches.filter(research => research.category.includes(categoryFilter)) : searchFilteredResearches;

  const allCategories = researches ? researches.map(item => item.category) : [];
  const categories = [...new Set(allCategories.flat())];

  const pageData = getCurrentPageData(filterResult, ITEM_PER_PAGE, page);

  const handleChangePage = (_event, page) => {
    setPage(page);
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const handleChangeCategory = (category) => {
    setCategoryFilter(category);
  };

  const handleClearFilter = () => {
    setDateFilter("");
    setCategoryFilter("");
  };

  return (
    <section>
      <TopLink />
      {/* Fitter */}
      <CardListFilter
        searchKey={searchKey}
        onSearch={handleSearch}
        dateFilter={dateFilter}
        onDateFilterChange={handleDateFilterChange}
        filterValue={categoryFilter}
        filterList={categories.map((category) => ({ label: category, value: category }))}
        onFilterChange={handleChangeCategory}
        onClearFilter={handleClearFilter}
      />
      {isLoading ? (
        <Box className="text-center" py={8}>
          <BeatLoader color="#5C5470" />
        </Box>
      ) : (
        <>
          {pageData.length == 0 ? (
            <Box className="text-center" py={8}>
              <Typography variant="h6">
              Oops No Researches found with this query
              </Typography>
            </Box>
          ) : (
            <section className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-12 mb-5 pt-24 px-20">
              {pageData.map((research) => (
                <ResearchCard
                  key={research.id}
                  title={research.title}
                  description={research.description}
                  image={research.image}
                  author={research["author"]}
                  url={"#"}
                  id={research.id}
                  category={research.category}
                  publishedYear={research.date}
                />
              ))}
            </section>
          )}

          <Box display="flex" justifyContent="center" width="100%" py={8}>
            <Pagination 
              count={getPageCount(filterResult.length, ITEM_PER_PAGE)} 
              variant="outlined" 
              shape="rounded" 
              onChange={handleChangePage}
            />
          </Box>
        </>
      )}
    </section>
  );
};

export default Research;
