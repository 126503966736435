const position = { x: 0, y: 0 };
const edgeType = "smoothstep";
const redToGreen = ["#ff0000", "#db1200", "#b62500", "#923700", "#6d4900", "#495b00", "#246e00", "#008000" ];

export const getDecisionTree = (decisionTree) => {
  const nodes = [];
  const edges = [];
  let i = 0;
  const addNode = (node) => {
    const condition = Math.round(node.split_condition);
    nodes.push({
      id: `${node.nodeid}`,
      data: { 
        ...node,
        ...(node.split_condition ? ({ split_condition: condition }) : {}),
        ...(node.split_condition ? {} : ({ bgColor: redToGreen[i] }) ) 
      },
      type: node.split ? "glint" : "trust",
      position
    });
    if(!node.split) {
      i++;
    }
    if (node.children) {
      node.children.sort((a, b) => a.leaf - b.leaf);
      node.children.forEach((child) => {
        edges.push({
          id: `e${node.nodeid}-${child.nodeid}`,
          source: node.nodeid.toString(),
          target: child.nodeid.toString(),
          type: edgeType,
          label: node.yes === child.nodeid ? `<${condition}` : `${condition === 100 ? "=100" : `>${condition}`}`,
          animated: true,
          labelStyle: {
            fontSize: "18px"
          }
        });
        addNode(child);
      });
    }
  };
  decisionTree.forEach((node) => addNode(node, 0));
  return { nodes, edges };
};

export const initialNodes = [
  {
    id: "1",
    type: "glint",
    data: { label: "input" },
    position,
  },
  {
    id: "2",
    data: { label: "node 2" },
    type: "glint",
    position,
  },
  {
    id: "2a",
    data: { label: "node 2a" },
    type: "glint",
    position,
  },
  {
    id: "2b",
    data: { label: "node 2b" },
    type: "glint",
    position,
  },
  {
    id: "2c",
    data: { label: "node 2c" },
    type: "glint",
    position,
  },
  {
    id: "2d",
    data: { label: "node 2d" },
    type: "glint",
    position,
  },
  {
    id: "3",
    data: { label: "node 3" },
    type: "glint",
    position,
  },
];

export const initialEdges = [
  { id: "e12", source: "1", target: "2", type: edgeType },
  { id: "e13", source: "1", target: "3", type: edgeType },
  { id: "e22a", source: "2", target: "2a", type: edgeType },
  { id: "e22b", source: "2", target: "2b", type: edgeType },
  { id: "e22c", source: "2", target: "2c", type: edgeType },
  { id: "e2c2d", source: "2c", target: "2d", type: edgeType },
  { id: "e45", source: "4", target: "5", type: edgeType },
  { id: "e56", source: "5", target: "6", type: edgeType },
  { id: "e57", source: "5", target: "7", type: edgeType },
];
