import React from "react";

import { Typography } from "@mui/material";
import ClusteredBarChart from "components/LocationInsights/ClusteredBarChart";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useSearchParams } from "react-router-dom";

const SearchTermByQuestion = ({ filteredComments }) => {
  const [currentParams] = useSearchParams();
  const searchQuery = currentParams.get("search");
  const countByQuestion = filteredComments.reduce((acc, item) => {
    if (!acc[item.question]) {
      acc[item.question] = 0;
    }

    acc[item.question]++;

    return acc;
  }, {});

  const SearchTermByQuestionChartData = Object.keys(countByQuestion)
    .map((question) => ({ 
      question, 
      count: countByQuestion[question] 
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 15);

  if(!SearchTermByQuestionChartData.length) {
    return null;
  }

  // The graph below illustrates the search term Trust, categorized by the number of comments detected by our model. 
  // The question care emerged as the most frequently commented question related to the search term Trust
  
  return (
    <LocationInsightCard title="Search Term by Question" data={SearchTermByQuestionChartData}>
      <Typography variant="body1" gutterBottom>
          The graph below illustrates the search term <b>{searchQuery}</b>, categorized by the number of comments detected by our model. 
          The question <b>{SearchTermByQuestionChartData[0].question}</b> emerged as the most frequently commented question related to <b>{searchQuery}</b>. 
        {SearchTermByQuestionChartData[0].question === "What Else" && (
          <span>
            {" "}<b>What Else</b> indicates that employees may have felt there were no questions related to <b>{searchQuery}</b> and left their comment as additional feedback.
          </span>
        )}
      </Typography>
      <ClusteredBarChart 
        id="term-frequency-chart"
        labelKey="question"
        data={SearchTermByQuestionChartData}
        height="500px"
        valueKey="count"
        valueLabel="Count"
        xLabel="Term frequency"
        yLabel="Category"
      />
    </LocationInsightCard>
  );
};

export default SearchTermByQuestion;
