import React, { useEffect } from "react";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import Filters from "./Filters/Filters";
import InternalViewTable from "./InternalViewTable/InternalViewTable";
import AttritionOverview from "./LocationInsightsInternalDetail/Tabs/Attrition/AttritionOverview";
import HeadcountOverview from "./LocationInsightsInternalDetail/Tabs/Headcount/HeadcountOverview";
import LeadershipDepthOverview from "./LocationInsightsInternalDetail/Tabs/LeadershipDepth/LeadershipDepthOverview";
import RecruitmentOverview from "./LocationInsightsInternalDetail/Tabs/Recruitment/RecruitmentOverview";
import TeamDistributionOverview from "./LocationInsightsInternalDetail/Tabs/TeamDistribution/TeamDistributionOverview";
import TimezoneOverview from "./LocationInsightsInternalDetail/Tabs/Timezone/TimezoneOverview";

const LocationInsightsInternalView = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const headCountSummaryData = data?.metrics?.find((item) => item.metric_name === "headcount_summary");
  const attritionOverviewData = data?.metrics?.find((item) => item.metric_name === "attrition_rates");
  const attritionClassificationData = data?.metrics?.find((item) => item.metric_name === "attrition_classification");
  const timezoneOverviewData = data?.metrics?.find((item) => item.metric_name === "tz_summary");
  const leadershipDepthOverviewData = data?.metrics?.find((item) => item.metric_name === "headcount_mgmt_level");
  const distributionOverviewData = data?.metrics?.find((item) => item.metric_name === "team_distribution_summary");
  const budgetedAndOpenRolesData = data?.metrics?.find((item) => item.metric_name === "tbh_req_summary");
  const tableData = data?.raw_data || [];

  return (
    <Box 
      sx={{
        pb: 4, 
        pt: 4, 
        px: 4, 
        bgcolor: "#F6F6F6"
      }}
    >
      <Typography variant="h2" mb={4}>
        Internal View
      </Typography>

      <Paper sx={{ mb: 1, flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Box p={4}>
          <Filters />
        </Box>
      </Paper>
      <Grid container mt={4} spacing={2}>
        {isLoading ? (
          <Grid item md={12}>
            <Box className="flex items-center justify-center w-full" sx={{ height: "calc(100vh - 400px)" }}>
              <BeatLoader color="#5C5470" />
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6} lg={4}>
              <HeadcountOverview data={headCountSummaryData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AttritionOverview data={attritionOverviewData} classificationData={attritionClassificationData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TimezoneOverview data={timezoneOverviewData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <LeadershipDepthOverview data={leadershipDepthOverviewData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <RecruitmentOverview data={budgetedAndOpenRolesData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TeamDistributionOverview data={distributionOverviewData} />
            </Grid>
            <Grid item xs={12} >
              <InternalViewTable 
                data={tableData}
              />
            </Grid>
          </>
        )}     
      </Grid>
    </Box>
  );
};

export default LocationInsightsInternalView;
