import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { MyTheme } from "components/LocationInsights/theme";

const TeamDistributionOverviewChart = ({ data }) => {

  useLayoutEffect(() => {
    const root = am5.Root.new("team-distribution-overview-chart");

    root.setThemes([MyTheme.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 30
        }),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraMax: 0.05,
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {
          // minGridDistance: 1,
          cellEndLocation: 0.9,
        })
      })
    );

    // Hide X-axis grid lines
    xAxis.get("renderer").grid.template.set("visible", false);
    xAxis.get("renderer").labels.template.setAll({
      fontSize: 12,
      ...(data.length > 8 ? {
        rotation: -50,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 0
      } : {
        oversizedBehavior: "wrap",
        maxWidth: 70,
        textAlign: "center"
      })
    });

    // Hide Y-axis grid lines
    yAxis.get("renderer").grid.template.set("visible", false);
    // Hide Y-axis tick labels
    yAxis.get("renderer").labels.template.set("visible", false);
    

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        // strokeOpacity: 1,
        // fillOpacity: 0.3
      })
    );

    series.columns.template.adapters.add("fill", (fill, target) => {
      return chart.get("colors").getIndex(target.dataItem.index);
    });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });
    series.columns.template.setAll({ 
      // strokeOpacity: 1,
      // fillOpacity: 0.3
    });
    // Add labels on top of bars
    series.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationY: 0.99,
        sprite: am5.Label.new(root, {
          text: "{value}",
          centerY: am5.p100,
          centerX: am5.p50,
          fontSize: 12,
          populateText: true
        })
      });
    });


    xAxis.data.setAll(data);
    series.data.setAll(data);


    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="team-distribution-overview-chart" style={{ width: "100%", height: "400px" }}></div>;
};

export default TeamDistributionOverviewChart;
