import React, { useEffect, useMemo } from "react";

import { Autocomplete, TextField, Grid, Box, Button } from "@mui/material";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetApplicationFiltersQuery } from "store/slices/commentSlice";

const getValues = (params, name) => {
  return params.get(name)?.split("_") || [];
};

const getOptions = (comments, key) => {
  const options = comments.map((item) => item[key]);
  return [...new Set(options)];
};

const SearchTopicFilter = ({ comments }) => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const { data: appFilters, isLoading: isFilterLoading } = useGetApplicationFiltersQuery({ page_name: "glint_comments" });
  const searchParam = currentParams.get("search");
  const dateFilterOptions = appFilters?.glint_comments?.survey_creation_date ? Object.values(appFilters.glint_comments.survey_creation_date) : [];

  useEffect(() => {
    if(dateFilterOptions.length > 0) {
      currentParams.set("dates", dateFilterOptions.at(-1));
      setCurrentParams(currentParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilterOptions.length]);

  const filters = useMemo(() => {
    return [
      {
        label: "Division",
        name: "division",
        options: getOptions(comments, "division"),
        value: getValues(currentParams, "division"),
      },

      {
        label: "Management Level",
        name: "mgmtLvl",
        options: getOptions(comments, "management_level"),
        value: getValues(currentParams, "mgmtLvl"),
      },
      {
        label: "Manager L2",
        name: "l2m",
        options: getOptions(comments, "l2_sup_org"),
        value: getValues(currentParams, "l2m"),
      },
      {
        label: "Manager L3",
        name: "l3m",
        options: getOptions(comments, "l3_sup_org"),
        value: getValues(currentParams, "l3m"),
      },
      {
        label: "Geo",
        name: "geo",
        options: getOptions(comments, "geo"),
        value: getValues(currentParams, "geo"),
      },
      {
        label: "Country",
        name: "country",
        options: getOptions(comments, "country"),
        value: getValues(currentParams, "country"),
      },
      {
        label: "Favorability",
        name: "favorability",
        options: getOptions(comments, "favorability"),
        value: getValues(currentParams, "favorability"),
      },
      {
        label: "Dates",
        name: "dates",
        options: getOptions(comments, "survey_date"),
        value: getValues(currentParams, "dates"),
      },
      {
        label: "Question",
        name: "question",
        options: getOptions(comments, "question"),
        value: getValues(currentParams, "question"),
      },
    ];
  }, [comments, currentParams]);

  const handleChange = (name, values) => {
    if(values.length === 0) {
      currentParams.delete(name);
    } else {
      currentParams.set(name, values.join("_"));
    }
    setCurrentParams(currentParams);
  };

  const handleClearAllFilters = () => {
    const params = new URLSearchParams();
    params.set("search", searchParam);
    setCurrentParams(params);
  };

  if(!searchParam) {
    return null;
  }

  if(isFilterLoading) {
    return <Box className="text-center" py={8}>
      <BeatLoader color="#5C5470" />
    </Box>;
  }

  return (
    <>
      <Grid container columnSpacing={4} rowSpacing={2} mt={2}>
        {filters.map((filter) => (
          <Grid item key={filter.name} md={4}>
            <Autocomplete
              multiple
              options={filter.options.sort()}
              getOptionLabel={(option) => filter.name === "dates" ? dayjs(option).format("MMM 'YY") : option}
              value={filter.value}
              size="small"
              onChange={(_e, values)=>handleChange(filter.name, values)}
              ChipProps={{
                variant: "contained",
                color: "primary",
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={filter.label}
                  placeholder={filter.label}
                />
              )}
            />
          </Grid>
        ))}
        <Grid item md={12}>
          <Box className="flex justify-center">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClearAllFilters}
              size="small"
            >
          Clear Filters
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchTopicFilter;
