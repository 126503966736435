import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextFormField from "components/FormComponents/TextFormField";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";

import PersonaCheckList from "../PermissionCheckList/PermissionCheckList";


const PersonaEditor = ({ open, onClose, persona, availableTreeValues, onCreate, onUpdate }) => {  
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const { handleSubmit, control, register, formState: { errors, isValid } } = useForm({
    values: {
      logic: persona?.logic || "",
      comments: persona?.comments || "",
      display_name: persona?.display_name || "",
      persona_permissions: persona?.persona_permissions || {}
    },
    mode: "all"
  });

  useEffect(() => {
    if(!persona) {
      register("persona_name", "");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      if(persona) {
        await onUpdate({ persona_name: persona.persona_name, data: values });
      } else {
        await onCreate({ data: values });
      }
      onClose(); 
    } catch (error) {
      if(error.status === 400) {
        enqueueSnackbar(error.data, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Persona Editor</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={6}>
            {!persona && (
              <Grid item md={12}>
                <TextFormField
                  name="persona_name"
                  label="Persona Name"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.persona_name}
                  rules={{
                    required: "Persona name is required"
                  }}
                />
              </Grid>
            )}
            <Grid item md={6}>
              <TextFormField
                name="display_name"
                label="Display Name"
                type="text"
                fullWidth
                control={control}
                error={errors.display_name}
                rules={{
                  required: "Display name is required"
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextFormField
                name="logic"
                label="Logic"
                type="text"
                fullWidth
                control={control}
                error={errors.logic}
                rules={{
                  required: "Logic is required"
                }}
              />
            </Grid>
            <Grid item md={12}>
              <TextFormField
                name="comments"
                label="Comments (Optional)"
                type="text"
                fullWidth
                control={control}
                error={errors.comments}
                multiline
                minRows={8}
              />
            </Grid>
          </Grid>
          {availableTreeValues && (
            <>
              <Typography variant="body1" mb={1} mt={4}>Permissions</Typography>
              <Controller 
                control={control}
                name="persona_permissions"
                render={({ field }) => (
                  <PersonaCheckList
                    value={field.value}
                    treeStructure={availableTreeValues} 
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button type="button" onClick={onClose}>Cancel</Button>
          <LoadingButton color="primary" variant="contained" type="submit" disabled={!isValid} loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PersonaEditor;
