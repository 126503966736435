
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InputOutlinedIcon from "@mui/icons-material/InputOutlined";
import OutputOutlinedIcon from "@mui/icons-material/OutputOutlined";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

const getUrl = (pathname, url, params) => {
  if(pathname.includes("external-view") && !url.includes("external-view")) {
    return url;
  }
  if(pathname.includes("internal-view") && !url.includes("internal-view")) {
    return url;
  }
  return `${url}?${params.toString()}`;
};

const useMenus = () => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const { pathname } = useLocation();
  const menus = getMenus(id);

  const menusWithActive = menus.map(menu => {
    if (menu.children) {
      const isActive = pathname.startsWith(menu.to);
      const childItems = menu.children.map(child => ({
        ...child,
        isActive: pathname.startsWith(child.to),
        to: getUrl(pathname, child.to, currentParams)
      }));
      return {
        ...menu,
        children: childItems,
        isActive: isActive,
        to: getUrl(pathname, menu.to, currentParams)
      };
    } else {
      return {
        ...menu,
        isActive: pathname.startsWith(menu.to)
      };
    }
  });

  return menusWithActive;
};

export default useMenus;

export const getMenus = (id) => ([
  {
    title: "Overview",
    icon: <ArticleOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/overview`
  },
  {
    title: "Internal View",
    icon: <InputOutlinedIcon sx={{ width: "20px", ml: "2px" }} />,
    to: `/dashboard/${id}/location-insights/internal-view`,

    children: [
      {
        title: "Headcount",
        to: `/dashboard/${id}/location-insights/internal-view/detail/headcount`
      },
      {
        title: "Attrition",
        to: `/dashboard/${id}/location-insights/internal-view/detail/attrition`
      },
      {
        title: "Timezone",
        to: `/dashboard/${id}/location-insights/internal-view/detail/timezone`
      },
      {
        title: "Leadership Depth",
        to: `/dashboard/${id}/location-insights/internal-view/detail/leadership-depth`
      },
      {
        title: "Open and Budgeted roles",
        to: `/dashboard/${id}/location-insights/internal-view/detail/recruitment`
      },
      {
        title: "Teams Based at Location",
        to: `/dashboard/${id}/location-insights/internal-view/detail/team-distribution`
      },
    ]
  },
  {
    title: "Market View",
    icon: <OutputOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/external-view`,
    children: [
      {
        title: "Talent Supply and Demand",
        to: `/dashboard/${id}/location-insights/external-view/detail/supply-and-demand`
      },
      {
        title: "Hiring Difficulty",
        to: `/dashboard/${id}/location-insights/external-view/detail/hiring-difficulty`
      },
      {
        title: "Talent Competition",
        to: `/dashboard/${id}/location-insights/external-view/detail/talent-competition`
      },
      {
        title: "Market Workforce Diversity",
        to: `/dashboard/${id}/location-insights/external-view/detail/diversity`
      },
      {
        title: "Role Analysis",
        to: `/dashboard/${id}/location-insights/external-view/detail/role-analysis`
      }
    ]
  },
  {
    title: "FAQs",
    icon: <HelpOutlineOutlinedIcon />,
    to: `/dashboard/${id}/location-insights/faqs`
  },
]) ;
