import React from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Typography, Box, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import styles from "./ResearchCard.module.scss";

const ResearchCard = ({
  title,
  description,
  image,
  id,
  publishedYear,
}) => {
  const newTitle = title.toLowerCase().replace(/\s/g, "-");
  return (
    <div className="rounded h-[500px] overflow-hidden shadow-lg">
      <a href="#"></a>
      <div className="relative">
        <Link to={`/research/${id}/${newTitle}`}>
          <img className="w-full h-[240px] object-cover" src={image} />
          <div className="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25"></div>
        </Link>
        <Link to={`/research/${id}/${newTitle}`}>
          <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
            Research
          </div>
        </Link>
      </div>
      <Box className="px-6 h-[200px] text-left py-4">
        <Box>
          <Tooltip title={title} arrow>
            <Typography
              component={Link}
              className={styles.title}
              to={`/research/${id}/${newTitle}`}
              variant="h4"
            >
              {title}
            </Typography>
          </Tooltip>
        </Box>
        <Box mt={2}>
          <Typography variant="body1" className={styles.description}>
            {description}
          </Typography>
        </Box>
      </Box>
      <div className="px-6 py-4 flex flex-row justify-end  items-center">
        <AccessTimeIcon />
        <span className="ml-1">{publishedYear}</span>
      </div>
      <div className="px-6 flex flex-row items-center justify-end mb-3">
        <span
          href="#"
          className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row items-center"
        ></span>
      </div>
    </div>
  );
};

export default ResearchCard;
