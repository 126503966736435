import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Chip, InputAdornment, TextField } from "@mui/material";
import { useDebounce } from "hooks/useDebounce";
import { useSearchParams } from "react-router-dom";

const suggestedComments = [
  "Trust",
  "Reliability",
  "Capacity",
  "Transparency",
  "Care",
  "Collaboration",
  "Action Taking",
  "Decision Speed",
  "Belonging",
  "Flexible Work",
  "Connection"
];

const SearchFilter = ({ searchQuery }) => {
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value);
  const [currentParams, setCurrentParam] = useSearchParams();

  useEffect(() => {
    if (debouncedValue) {
      currentParams.set("search", debouncedValue);
    } else {
      currentParams.delete("search");
    }
    setCurrentParam(currentParams);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleSearchChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if(searchQuery) {
      setValue(searchQuery);
    }
  }, [searchQuery]);

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
        <TextField
          value={value}
          type="text"
          onChange={handleSearchChange}
          placeholder="Search Comments"
          sx={{ width: "800px" }}
          InputProps={{
            sx: {
              borderRadius: "50px"
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box pt={2} textAlign="center">
        {suggestedComments.map((comment, index) => (
          <Chip 
            variant={value === comment ? "contained" : "outlined"}
            key={index} 
            label={comment} 
            color="primary"
            sx={{ m: 0.5 }} 
            onClick={() => handleSearchChange({ target: { value: comment } })}
          />
        ))}
      </Box>
    </div>
  );
};

export default SearchFilter;
