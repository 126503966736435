import { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import { MyTheme } from "./theme";

const OneLineStackedBarChart = ({ id="test-chart", data:chartData, dataKeys, height="200px" }) => {
  const data = chartData.map((row) => ({ yValue: "1", ...row }));

  // This code will only run one time
  useLayoutEffect(() => {
    const root = am5.Root.new(id);
    root._logo.dispose();


    // root.setThemes([
    //   MyTheme.new(root)
    // ]);
    
    
    // const myTheme = am5.Theme.new(root);

    // myTheme.rule("Grid", ["base"]).setAll({
    //   strokeOpacity: 0.1
    // });


    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      MyTheme.new(root)
    ]);


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      paddingLeft: 0,
      layout: root.verticalLayout
    }));


    // const data = [{
    //   "year": "2021",
    //   "europe": 2.5,
    //   "namerica": 2.5,
    //   "asia": 2.1,
    //   "lamerica": 1,
    //   "meast": 0.8,
    //   "africa": 0.4
    // }];


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set("forceHidden", true);
    yRenderer.labels.template.set("forceHidden", true);
    
    const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "yValue",
      renderer: yRenderer,
      tooltip: am5.Tooltip.new(root, {})
    }));

    yAxis.data.setAll(data);

    const xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("forceHidden", true);
    xRenderer.labels.template.set("forceHidden", true);

    const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      max: 100,
      renderer: xRenderer,
      calculateTotals: true,
    }));

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName) {
      const series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        baseAxis: yAxis,
        valueXField: fieldName,
        valueXShow: "valueXTotalPercent",
        categoryYField: "yValue"
      }));

      series.columns.template.setAll({
        tooltipText: "{name}: {valueX}%",
        tooltipY: am5.percent(90),
      });
      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueXTotalPercent.formatNumber('#.#')}%",
            // fill: am5.color(0x000),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      legend.data.push(series);
    }

    dataKeys.forEach(({ name, key, color }) => {
      makeSeries(name, key, color);
    });

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return <div id={id} style={{ width: "100%", height }}></div>;
};
export default OneLineStackedBarChart;
