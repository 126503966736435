import React from "react";

import Philosophy from "./Philosophy";
import ShareIdea from "./ShareIdea";
import Upcoming from "./Upcoming";

const TopLink = () => {
  return (
    <>
      <div className="text-center text-gray-800 text-xl mb-2 font-bold">
        Learn more about our
      </div>
      <div className="flex items-center justify-center gap-10 mb-10 text-lg text-sky-400">
        <Philosophy />
        <Upcoming />
        <ShareIdea />
      </div>
      {/* <Modal
        open={openForm}
        onClose={() => formCloseHandler()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}></Box></Modal> */}
    </>
  );
};

export default TopLink;
