import React, { useState } from "react";

import { Typography, Box, Pagination } from "@mui/material";
import CardListFilter from "components/CardListFilter/CardListFilter";
import dayjs from "dayjs";
import BeatLoader from "react-spinners/BeatLoader";
import { useGetAllBlogsQuery } from "store/slices/blogSlice";
import { getCurrentPageData, getPageCount } from "utils/pagination";

import BlogCard from "./BlogCard";

const ITEM_PER_PAGE = 6;

const Blog = () => {
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [page, setPage] = useState(1);

  const { data: blogs = [], isLoading } = useGetAllBlogsQuery();

  const searchedBlogs = blogs.filter(blog => blog.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredBlogs = dateFilter ? searchedBlogs.filter(blog => dayjs(blog.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedBlogs;
  const filterResult = categoryFilter ? searchFilteredBlogs.filter(blog => blog.category.includes(categoryFilter)) : searchFilteredBlogs;

  const allCategories = blogs ? blogs.map(item => item.category) : [];
  const categories = [...new Set(allCategories.flat())];

  const pageData = getCurrentPageData(filterResult, ITEM_PER_PAGE, page);

  const handleChangePage = (_event, page) => {
    setPage(page);
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const handleChangeCategory = (category) => {
    setCategoryFilter(category);
  };

  const handleClearFilter = () => {
    setDateFilter("");
    setCategoryFilter("");
  };

  return (
    <section>
      {/* Fitter */}
      <Box pt={4}>
        <CardListFilter
          searchKey={searchKey}
          onSearch={handleSearch}
          dateFilter={dateFilter}
          onDateFilterChange={handleDateFilterChange}
          filterValue={categoryFilter}
          filterList={categories.map((category) => ({ label: category, value: category }))} 
          onFilterChange={handleChangeCategory}
          onClearFilter={handleClearFilter}
        />
      </Box>
      {isLoading ? (
        <Box className="text-center" py={8}>
          <BeatLoader color="#5C5470" />
        </Box>
      ) : (
        <>
          {pageData.length == 0 ? (
            <Box className="text-center" py={8}>
              <Typography variant="h6">
              Oops No Blogs found with this query
              </Typography>
            </Box>
          ) : (
            <section className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-12 mb-5 pt-24 px-20">
              {pageData.map((blog) => (
                <BlogCard
                  key={blog.id}
                  title={blog.title}
                  description={blog.description}
                  image={blog.image}
                  author={blog.author}
                  url={"#"}
                  id={blog.id}
                  category={blog.category}
                  publishedYear={blog.date}
                />
              ))}
            </section>
          )}

          <Box display="flex" justifyContent="center" width="100%" py={8}>
            <Pagination 
              count={getPageCount(filterResult.length, ITEM_PER_PAGE)} 
              variant="outlined" 
              shape="rounded" 
              onChange={handleChangePage}
            />
          </Box>
        </>
      )}
    </section>
  );
};

export default Blog;
