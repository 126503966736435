import React from "react";

import { Box, Link } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

import TeamDistributionOverviewChart from "./TeamDistributionOverviewChart";

const TeamDistributionOverview = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  const chartData = data?.value ? Object.entries(data.value).map(([key, value]) => ({
    category: key,
    value: Math.round(+value),
  })) : [];
  
  return (
    <LocationInsightCard title="Team Distribution Overview" data={data?.value}>
      <TeamDistributionOverviewChart data={chartData} />
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/internal-view/detail/team-distribution?${currentParams.toString()}`}>
            View Location Team Distribution Details 
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export default TeamDistributionOverview;
