import React, { useMemo, useState } from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";

import EmployeeListeningFilter from "./EmployeeListeningFilter";

const LifecycleSurveysManagement = ({ data, isLoading = false, onEdit, onRemove, onCreateNew }) => {

  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState(null);

  const searchedData = data.filter(research => research.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredData = dateFilter ? searchedData.filter(research => dayjs(research.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedData;

  const columns = useMemo(() => {
    return [
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        width: 120,
        renderCell: (params) => (
          <>
            <IconButton onClick={() => onEdit(params.row)}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton onClick={() => onRemove(params.row.id)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        ),
      },
      { field: "id", headerName: "ID", width: 90 },
      {
        field: "title",
        headerName: "Title",
        width: 200,
        editable: true,
      },
      {
        field: "description",
        headerName: "Description",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        width: 300,
      },
      {
        field: "date",
        headerName: "Year",
        width: 100,
      },
      {
        field: "category",
        headerName: "Category",
        width: 150,
      },
      {
        field: "item_type",
        headerName: "Type",
        width: 150,
      },
    ];
  }, [onEdit, onRemove]);

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  return (
    <>
      <EmployeeListeningFilter
        onCreateNew={onCreateNew}
        searchKey={searchKey}
        onSearch={handleSearch}
        dateFilter={dateFilter}
        onDateFilterChange={handleDateFilterChange}
      />
      <Box sx={{ height: 800, width: "100%", backgroundColor: "white" }}>
        <DataGrid
          rows={searchFilteredData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}

          loading={isLoading}
          pageSizeOptions={[10]}
          // checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};

export default LifecycleSurveysManagement;
