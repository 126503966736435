

import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";

const convertData = (data) => {
  const chartData = [];
  for (const category in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(category)) {
      for (const subCategory in data[category]) {
        // eslint-disable-next-line no-prototype-builtins
        if (data[category].hasOwnProperty(subCategory)) {
          chartData.push({
            category: category,
            subCategory: subCategory,
            value: parseInt(data[category][subCategory])
          });
        }
      }
    }
  }
  return chartData;
};

function calculateMedian(data) {
  const values = data.map(item => item.value);
  values.sort((a, b) => a - b);

  return (values[0] + values[values.length - 1] )/ 2;
}


function interpolateColor(value, minValue, maxValue, minColor, maxColor) {
  // Normalize the value between 0 and 1
  var ratio = (value - minValue) / (maxValue - minValue);

  // Interpolate each color component
  var r = Math.round(minColor.r + ratio * (maxColor.r - minColor.r));
  var g = Math.round(minColor.g + ratio * (maxColor.g - minColor.g));
  var b = Math.round(minColor.b + ratio * (maxColor.b - minColor.b));

  return am5.color(r * 0x10000 + g * 0x100 + b); // Convert RGB to hex
}

const LeadershipDepthCount = ({ locationValue }) => {
  const chartData = convertData(locationValue);
  const medianValue = calculateMedian(chartData);
  
  useLayoutEffect(() => {
    const root = am5.Root.new("leadership-depth-count");
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    root._logo.dispose();

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      paddingLeft: 0,
      layout: root.verticalLayout
    }));


    // Create axes and their renderers
    let yRenderer = am5xy.AxisRendererY.new(root, {
      visible: false,
      minGridDistance: 20,
      inversed: true,
      minorGridEnabled: true
    });

    yRenderer.grid.template.set("visible", false);

    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      renderer: yRenderer,
      categoryField: "category"
    }));

    let xRenderer = am5xy.AxisRendererX.new(root, {
      visible: false,
      minGridDistance: 30,
      opposite:true,
      minorGridEnabled: true
    });

    xRenderer.grid.template.set("visible", false);

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      renderer: xRenderer,
      categoryField: "subCategory"
    }));


    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      calculateAggregates: true,
      stroke: am5.color(0xffffff),
      clustered: false,
      xAxis: xAxis,
      yAxis: yAxis,
      categoryXField: "subCategory",
      categoryYField: "category",
      valueField: "value"
    }));

    series.columns.template.setAll({
      // tooltipText: "{category}, {subCategory}: {value}",
      strokeOpacity: 1,
      strokeWidth: 2,
      width: am5.percent(100),
      height: am5.percent(100)
    });

    // Define min, mid, and max colors
    const maxColor = am5.color(0x051b40); 
    const midColor = am5.color(0x176bff); 
    const minColor = am5.color(0xcce1ff); 

    // Set up heat rules
    // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
    series.set("heatRules", [{
      target: series.columns.template,
      dataField: "value",
      customFunction: function(sprite, min, max, value) {
        const mid = (min + max) / 2;
        if (value <= mid) {
          sprite.set("fill", interpolateColor(value, min, mid, minColor, midColor));
        } else {
          sprite.set("fill", interpolateColor(value, mid, max, midColor, maxColor));
        }
      }
    }]);

    series.bullets.push(function(root, series, dataItem) {
      const value = dataItem.get("value");
      const labelColor = value > medianValue ? am5.color(0xffffff) : am5.color(0x000000);
    
      return am5.Bullet.new(root, {
        locationX: 0.5,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: "{value}",
          populateText: true,
          centerX: am5.p50,
          centerY: am5.p50,
          fill: labelColor
        })
      });
    });

    series.data.setAll(chartData);

    // Auto-populate X and Y axis category data
    let categories = [];
    let subCategories = [];
    am5.array.each(chartData, function(row) {
      if (categories.indexOf(row.category) == -1) {
        categories.push(row.category);
      }
      if (subCategories.indexOf(row.subCategory) == -1) {
        subCategories.push(row.subCategory);
      }
    });


    yAxis.data.setAll(categories.map(function(item) {
      return { category: item };
    }));


    xAxis.data.setAll(subCategories.map(function(item) {
      return { subCategory: item };
    }));

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Leadership Counts at Location by Division
      </Typography>
      <div id="leadership-depth-count" style={{ width: "100%", height: "500px" }} ></div>
    </Box>
  );
};

export default LeadershipDepthCount;

