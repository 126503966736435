import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";

const convertData = (teamData, locationData) => {
  return [
    { category: "Directors", teamValue: +teamData["Directors"], locationValue: +locationData["Directors"] },
    { category: "Senior Directors", teamValue: +teamData["Senior Directors"], locationValue: +locationData["Senior Directors"] },
    { category: "VPs", teamValue: +teamData["VPs"], locationValue: +locationData["VPs"] },
    { category: "SVPs and Above", teamValue: +teamData["SVPs and above"], locationValue: +locationData["SVPs and above"] },
  ];
};


const BarChart = ({ teamValue, locationValue }) => {

  const chartData = convertData(teamValue, locationValue);
  
  useLayoutEffect(() => {
    const root = am5.Root.new("chartdiv");
    root.setThemes([
      MyTheme.new(root)
    ]);
    root._logo.dispose();

    let container = root.container.children.push(am5.Container.new(root, {
      layout: root.verticalLayout,
      width: am5.percent(100),
      height: am5.percent(100),
    }));

    const chart = container.children.push(
      am5xy.XYChart.new(root, {
        paddingBottom: 50,
        height: am5.percent(80),
      })
    );

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    let xRenderer = am5xy.AxisRendererX.new(root, { 
      minGridDistance: 30, 
      minorGridEnabled: true,
    });

    xRenderer.labels.template.setAll({
      oversizedBehavior: "wrap",
      textAlign: "center",
      fontSize: 12
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.03,
        renderer: am5xy.AxisRendererY.new(root, {
          minGridDistance: 30, 
          cellEndLocation: 0.9,
        })
      })
    );

    const addSeries = (name, field) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "category",
          clustered: true
        })
      );

      series.columns.template.setAll({
        // tooltipText: "{category}: {valueY}",
        tooltipY: 0,
        // strokeOpacity: 1,
        // fillOpacity: 0.3
      });

      series.bullets.push(() => {
        return am5.Bullet.new(root, {
          locationY: 1,
          
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            centerY: am5.percent(80),
            fill: am5.color(0x000000),
            fontSize: 12,
            centerX: am5.p50,
            populateText: true,
          })
        });
      });

      return series;
    };

    const teamSeries = addSeries("Autodesk", "teamValue");
    const locationSeries = addSeries("Team", "locationValue");

    xAxis.data.setAll(chartData);
    teamSeries.data.setAll(chartData);
    locationSeries.data.setAll(chartData);

    const legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      x: am5.percent(50),
      y: am5.p100
    }));

    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Leadership Depth at Location
      </Typography>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    </Box>
  );
};

export default BarChart;
