import { Typography } from "@mui/material";


export default {
  HiringDifficulty: {
    title: "Hiring Difficulty",
    content: (
      <>
        <Typography variant="body1" gutterBottom mb={1} fontWeight="bold">
          The hiring difficulty information from TalentNeuron takes the following factors into account:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              Supply-demand ratio (calculated as supply divided by demand, and only applicable when supply data is available)
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Typical posting duration
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Change in demand
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              Competitive concentration
            </Typography>
          </li>
        </ul>
      </>
    )
  },
  talentCompetition: {
    title: "Talent Competition",
    content: (
      <ul>
        <li>
          <Typography variant="body1" gutterBottom mb={1}>
            A dispersed market (low competitive concentration) means that one is competing with a larger number of more evenly matched organizations.
          </Typography>
        </li>
        <li>
          <Typography variant="body1" gutterBottom mb={1}>
            A concentrated market (high competitive concentration) means that one is competing with a smaller number of more dominant organizations. 
          </Typography>
        </li>
      </ul>
    )
  },
};
