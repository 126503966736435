import React from "react";

import { Alert, Card, CardContent, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useGetInsightsGenieQuery } from "store/slices/insightsGenieSlice";

import DateRange from "./DateRange/DateRange";
import DomainSelect from "./DomainSelect/DomainSelect";
import DownloadExcel from "./DownloadExcel/DownloadExcel";
import SelectOptions from "./SelectOptions/SelectOptions";

const InsightsGenie = () => {
  const { data, isLoading } = useGetInsightsGenieQuery();
  const [currentParams] = useSearchParams();
  const domain = currentParams.get("domain");
  const dateData = data?.[domain];

  if (isLoading) {
    return (
      <Box className="flex justify-center items-center" py={8} height="calc(100vh - 148px)">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!data) {
    return null;
  }

  return (
    <Box height="calc(100vh - 148px)">
      <Container maxWidth="lg">
        <Typography textAlign="center" variant="h1" my={8}>Insights Genie</Typography>
        <Card>
          <CardContent sx={{ p: 4 }}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <SelectOptions data={data} />
              </Grid>
              <Grid item md={12}>
                <DomainSelect domains={data.domains} />
              </Grid>
              {dateData ? (
                <Grid item md={6}>
                  <DateRange data={dateData} />
                </Grid>
              ) : (
                <Alert severity="warning">This is a warning Alert.</Alert>
              )}
            </Grid>
            <DownloadExcel />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default InsightsGenie;
