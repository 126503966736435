import React from "react";

import { Typography } from "@mui/material";
import LineChart from "components/LocationInsights/LineChart";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const SearchTermResultsOverTime = ({ data }) => {
  const [currentParams] = useSearchParams();
  const searchTerm = currentParams.get("search");
  const countsByDate = data?.reduce((acc, item) => {
    const dateKey = dayjs(item.survey_date).valueOf();
    if (!acc[dateKey]) {
      acc[dateKey] = 0;
    }
    
    acc[dateKey]++;
    
    return acc;
  }, {});
  
  const chartData = Object.entries(countsByDate).map((
    [date, count]) => ({ date: Number(date), count })
  ).sort((a, b) => a.date - b.date);

  const x = chartData.length > 1 ? Math.round((chartData.at(-1).count - chartData.at(-2).count) / chartData.at(-2).count * 100) : 0;

  return (
    <LocationInsightCard title="Search Term Results Over Time" data={chartData}>
      {x > 0 && (
        <Typography variant="body1" gutterBottom mb={2}>
          The graph below shows  the trend of the search term <b>{searchTerm}</b> over time. Since the last survey, the number of comments related to <b>{searchTerm}</b> has 
          <b>{x > 0 ? " increased" : " decreased"}</b> by <b>{Math.abs(x)}%.</b>
        </Typography>
      )}
      <LineChart 
        data={chartData} 
        xKey="date" 
        yKey="count" 
        categoryKey="date" 
        height="500px" 
        showGrid={false} 
        xLabel="Survey date"
        yLabel="Number of relevant comments"
      />
    </LocationInsightCard>
  );
};

export default SearchTermResultsOverTime;
