import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const dashboardApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDashboards: builder.query({
      query: () => ({
        url: "/dashboardTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
        },
      }),
      providesTags: ["Dashboards"]
    }),
    getDashboard: builder.query({
      query: ({ id }) => ({
        url: "/dashboardTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
      }),
    }),
    postDashboard: builder.mutation({
      query: ({ ext, data }) => ({
        url: "/dashboardTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          ext
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["Dashboards"]
    }),
    updateDashboard: builder.mutation({
      query: ({ id, ext, data }) => ({
        url: "/dashboardTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
          ext
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["Dashboards"]
    }),
    deleteDashboard: builder.mutation({
      query: ({ id }) => ({
        url: "/dashboardTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
        },
        method: "DELETE"
      }),
      invalidatesTags: ["Dashboards"]
    }),
  }),
});


export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
});

export const { 
  useUpdateDashboardMutation, 
  usePostDashboardMutation, 
  useDeleteDashboardMutation,
  useGetAllDashboardsQuery,
  useGetDashboardQuery,
  useLazyGetDashboardQuery
} = dashboardApi;

