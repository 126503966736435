import React, { useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const DateRange = ({ data }) => {
  const [dates, setDates] = useState([data[0]]);
  const [currentParams, setCurrentParams] = useSearchParams();

  useEffect(() => {
    if(dates.length === 0) {
      currentParams.delete("dates");
    } else {
      currentParams.set("dates", dates);
    }
    setCurrentParams(currentParams);
  }, [dates]);

  const handleClickDate = (date) => {
    if (dates.includes(date)) {
      setDates(prev => prev.filter(d => d !== date));
    } else {
      setDates(prev => [...prev, date]);
    }
  };

  return (
    <Box display="flex" gap={4} my={2}>
      {data.map(d => (
        <Button key={d} variant={dates.includes(d) ? "contained" : "outlined"} onClick={() => handleClickDate(d)}>
          {dayjs(data).format("MMM 'YY")}
        </Button>
      ))}
    </Box>
  );
};

export default DateRange;
