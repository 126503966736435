import React from "react";

const PowerBI = ({ val }) => {
  return (
    <div>
      {" "}
      <iframe
        title="Degreed data dashboard"
        style={{ width: "100vw", height: "95vh" }}
        src={val}
        // src="https://app.powerbi.com/reportEmbed?reportId=41827f22-55d8-425e-a835-48ae321535e2&autoAuth=true&ctid=67bff79e-7f91-4433-a8e5-c9252d2ddc1d"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default PowerBI;
