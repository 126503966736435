import * as React from "react";

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Box, Fade, IconButton, Tooltip } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Footer from "components/Footer/Footer";
import Header from "components/Header";
import { Outlet, useNavigate } from "react-router-dom";

import useMenus from "./useMenus";


const LocationInsightsLandingPage = () => {
  const [expanded, setExpanded] = React.useState(true);
  const items = useMenus();
  console.log({ items });

  const handleExpand = () => {
    setExpanded(prev => !prev);
  };

  return (
    <Box>
      <Header pageName="Location Insights" />
      <Box className="flex">
        <Paper
          sx={{
            width: expanded ? 240 : 56,
            transition: "width 0.3s",
            maxWidth: "100%",
            height: "calc(100vh - 56px)",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            pt: 16,
            pb: 4,
            borderRadius: 0,
            flexShrink: 0,
          }}
        >
          <MenuList>
            <Box>
              {items.map((item, index) => (
                <SideNavItem
                  key={index}
                  title={item.title}
                  icon={item.icon}
                  isExpanded={expanded}
                  to={item.to}
                  childItems={item.children}
                  isActive={item.isActive}
                />
              ))}
            </Box>
          </MenuList>
          <SideNavExpandItem isExpanded={expanded} onClickToggleExpand={handleExpand} />
        </Paper>
        <Box height="calc(100vh - 56px)" overflow="auto">
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default LocationInsightsLandingPage;


const SideNavExpandItem = ({
  isExpanded,
  onClickToggleExpand,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        cursor: "initial",
        alignItems: "center",
      }}
    >
      <Tooltip
        title={isExpanded ? "Collapse Navigation" : "Expand Navigation"}
        arrow
        placement="right"
      >
        <IconButton
          onClick={onClickToggleExpand}
          sx={{ mr: 0.5 }}
        >
          <ListItemIcon sx={{ minWidth: "28px !important" }}>
            {isExpanded ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </ListItemIcon>
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const SideNavItem = ({ title, icon, isExpanded, to, childItems, isActive }) => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  const content = (
    <>
      <MenuItem
        sx={{ my: 2 }}
        onClick={() => handleClick(to)}
        selected={isActive}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <Fade in={isExpanded} timeout={500}>
          <ListItemText>{title}</ListItemText>
        </Fade>
      </MenuItem>
      {childItems && isExpanded && isActive && (
        childItems.map((child, index) => (
          <MenuItem
            key={index}
            sx={{ my: 1 }}
            onClick={() => handleClick(child.to)}
            selected={child.isActive}
          >
            <ListItemIcon></ListItemIcon>
            <ListItemText>{child.title}</ListItemText>
          </MenuItem>
        ))
      )}
    </>
  );

  if(!isExpanded) {
    return (
      <Tooltip
        title={title}
        arrow
        placement="right"
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};
