import React, { useEffect } from "react";

import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGetLocationFiltersQuery } from "store/slices/locationSlice";

const JobTitleFilter = () => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const { data: filterData } = useGetLocationFiltersQuery();
  const filterOptions = filterData?.["location-insights-external"] || [];
  
  const job = currentParams.get("job_title");
  const jobOptions = filterOptions?.job_code || {};
  const selectedJob = { label: job || "", value: jobOptions[job] || "" };
  const selectMenus = Object.entries(jobOptions).map(([label, value]) => ({ label, value })); 

  useEffect(() => {
    if(selectMenus.length > 0 && !job) {
      currentParams.set("job_code", selectMenus[0].value);
      currentParams.set("job_title", selectMenus[0].label);
      setCurrentParams(currentParams);
    }
  }, [selectMenus, job]);

  const handleChangeJob = (value) => {
    if(!value?.value) {
      currentParams.delete("job_code");
      currentParams.delete("job_title");
    } else {
      currentParams.set("job_code", value.value);
      currentParams.set("job_title", value.label);
    }
    setCurrentParams(currentParams);
  };

  return (
    <>
      <Typography variant="body1" gutterBottom>Job Title</Typography>
      <FormControl size="small" sx={{ minWidth: 160 }}>
        <Autocomplete
          value={selectedJob}
          onChange={(_e, values)=>handleChangeJob(values)}
          size="small"
          ChipProps={{
            variant: "contained",
            color: "primary",
          }}
          getOptionLabel={(option) => option.label}
          options={selectMenus.sort((a, b) => a.label.localeCompare(b.label)) || []}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ width: "400px" }}
              variant="outlined"
              placeholder="Job Title"
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default JobTitleFilter;
