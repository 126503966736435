import React from "react";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Divider, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import Filters from "./Filters/Filters";

const getTabs = (basePath) => [
  {
    value: "supply-and-demand",
    label: "Talent Supply and Demand",
    path: `${basePath}/supply-and-demand`
  },
  {
    value: "hiring-difficulty",
    label: "Hiring Difficulty",
    path: `${basePath}/hiring-difficulty`
  },
  {
    value: "talent-competition",
    label: "Talent Competition",
    path: `${basePath}/talent-competition`
  },
  {
    value: "diversity",
    label: "Market Workforce Diversity",
    path: `${basePath}/diversity`
  },
  {
    value: "role-analysis",
    label: "Role Analysis",
    path: `${basePath}/role-analysis`
  }
];

const ExternalViewDetailLayout = () => {
  const { pathname } = useLocation();
  const [currentParams] = useSearchParams();
  const { id: dashboardId } = useParams();
  const navigate = useNavigate();
  const tabs = getTabs(`/dashboard/${dashboardId}/location-insights/external-view/detail`);
  
  const handleBack = () => {
    navigate(`/dashboard/${dashboardId}/location-insights/external-view?${currentParams.toString()}`);
  };

  return (
    <Box p={4} bgcolor="#F6F6F6">
      <Button sx={{ mb:4 }} size="small" onClick={handleBack} startIcon={<KeyboardBackspaceIcon />}>Back</Button>
      <Paper sx={{ p:4 }}>
        <Filters />
      </Paper>

      <Paper sx={{ px:4, py: 2, mt: 4 }}>
        <Tabs
          value={pathname}
        >
          {tabs.map((tab) => (
            <Tab 
              key={tab.value} 
              label={tab.label} 
              value={tab.path}
              to={`${tab.path}?${currentParams.toString()}`}
              component={NavLink}
            />
          ))}
        </Tabs>
        <Divider sx={{ mx: -4 }}/>
        <Box my={4}>
          <Outlet />
        </Box>
      </Paper>
    </Box>
  );
};

export default ExternalViewDetailLayout;
