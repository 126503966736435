import React from "react";

import { Box, Container, Typography } from "@mui/material";
import backgroundImage from "img/banner-bg.jpg";
import interpretImage from "img/decision-tree-interpret.png";
import overviewImage from "img/decision-tree-overview.png";

const GlintDecisionOverview = () => {

  return (
    <Box>
      <Box
        sx={{
          height: "200px",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
        }}
      >
        <Typography className="text-white" variant="h2">
          Decision Tree
        </Typography>
      </Box>

      <Container maxWidth="lg" sx={{ pt: 16, pb: 10 }}>
        <Box>
          <Typography variant="h2" gutterBottom fontWeight="bold">
            What is a Decision Tree?
          </Typography>
          <Typography variant="body1" fontSize="18px" gutterBottom>
          A Decision Tree Analysis is a visualization that pulls data from the most recent Employee Insights survey (also known as Glint) to better understand a specific <b>outcome</b>. 
          </Typography>
          <Typography variant="body1" fontSize="18px">
            Decision Trees help us understand 
            1) what <b>factors</b> are most related to the outcome, and 2) the magnitude, or <b>predicted score</b>, of how different Autodesk employees feel about the outcome. 
          </Typography>
          <Box display="flex" justifyContent="center" width="center" py={8}>
            <img src={overviewImage} width="100%" height="300px" />
          </Box>
        </Box>

        <Box>
          <Typography variant="h2" gutterBottom fontWeight="bold">
            How do I interpret Decision Trees?
          </Typography>
          <Box mb={8}>
            <Typography variant="body1" fontSize="18px" gutterBottom>
              Start at the top (most related factor) and trace a path to the bottom of the tree (predicted scores). 
            </Typography>
            <Typography variant="body1" fontSize="18px" mt={1}>
            In this example, we're seeking to better understand our outcome: <b>Employee Connection</b>. The first related factor is <b>Belonging</b>. If <b>Belonging</b> is <b>equal to 100</b>, and <b>Decision-Making</b> is <b>equal to 100</b>, and <b>Network</b> is <b>equal to 100</b>, then the predicted <b>Employee Connection</b> score is <b>98</b>, which is true for n = 1,295 Autodesk employees.
            </Typography>
          </Box>
          <img src={interpretImage} width="100%" height="300px" />
        </Box>

        <Box mt={8}>
          <Typography variant="h2" gutterBottom fontWeight="bold">
          How do we draw insights from Decision Trees?
          </Typography>
          <Typography variant="body1" fontSize="18px" gutterBottom>
            <b>Combination of factors</b> – when reviewing the factors that are most related to the outcome. Are any one of these, or is the combination of these, surprising in any way? 
          </Typography>
          <Typography variant="body1" fontSize="18px">
            <b>Path/Resulting N number</b> – trace each path from top to bottom and notice: are there any paths that are surprising, or any N numbers that are not aligned to your expectations? 
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h2" gutterBottom fontWeight="bold">
          What should we be careful about when reviewing Decision Trees?
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" fontSize="18px" gutterBottom>
                <b>The outcome is the main topic we are looking at more deeply.</b> Be careful that you do not mistake the top factor for the outcome.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" fontSize="18px" gutterBottom>
                <b>Factors are related to the outcome, we cannot assume they cause the outcome.</b> In some cases, the outcome may cause the factors. It is up to you as the viewer to take this information as an input not “the only answer.”
              </Typography>
            </li>
            <li>
              <Typography variant="body1" fontSize="18px" gutterBottom>
                <b>The dataset we’re drawing from can change.</b> Decision Tree Analysis can be conducted for Autodesk employees as a whole or for different groups within the Autodesk employee population. Be mindful of what you’re looking at.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" fontSize="18px" gutterBottom>
                <b>This information is based on what we know from the Employee Insights survey.</b> If you’re working to improve an outcome, remember to look at the big picture with this information as one input.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" fontSize="18px" gutterBottom>
                <b>You might see repeating factors within the same tree.</b> As you trace each path and move down each level, the greater than/less than for each factor is for the subset of people based on the factor above.
              </Typography>
            </li>
            <li>
              <Typography variant="body1" fontSize="18px" gutterBottom>
                <b>The decision trees are highly related to the outcome.</b> The trees presented in the application are curated by our data scientists. If a tree is not shown for an outcome, there was either not enough response data to power an accurate decision tree or there were no strong relationships between the factors and the outcome.
              </Typography>
            </li>
          </ul>
        </Box>
      </Container>


    </Box>
  );
};

export default GlintDecisionOverview;
