import React from "react";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import backgroundImage from "img/banner-bg.jpg";
import question from "img/question.png";
import { useNavigate, useParams } from "react-router-dom";

const LocationInsightsOverviewPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleGoToFAQs = () => {
    navigate(`/dashboard/${id}/location-insights/faqs`);
  };

  const handleEmail = () => {
    window.location.href = "mailto:PEX.People.Insights@autodesk.com?subject=Feedback%2FQuery%20about%20People%20Insights%20Portal";
  };

  return (
    <Box>
      <Box
        sx={{
          height: "200px",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
        }}
      >
        <Typography className="text-white" variant="h2">
          Location Insights Overview
        </Typography>
      </Box>

      <Container maxWidth="lg" sx={{ pt: 16, pb: 10 }}>
        <Grid container rowSpacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom>
            What is location strategy? 
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" fontSize="18px" mb={4}>
            The purpose of a location strategy is to position a company’s workforce to maximize access to skilled talent in locations that align with the organization's goals and objectives, while balancing both internal and external considerations. 
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom>
              Why is it important? 
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" fontSize="18px" gutterBottom>
              Continuously evaluating location strategy is critical because it ensures alignment with evolving market dynamics, optimizes operational efficiency, and enables adaptability to changing business needs and opportunities. 
              Have more questions? See our FAQs or email the People Insights Team  
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom>
            How should this app be used? 
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" fontSize="18px" gutterBottom>
              This application provides location-based Autodesk data extracted from our internal systems, as well as external data mainly TalentNeuron. Follow these guidelines to get the most out of this application: 
            </Typography>
            <ol className="my-4">
              <li className="my-4 leading-normal">
                Use the filters to obtain targeted insights. You may select None, or multiple filters but note using more filters may increase how manageable the volume of outputs is.
              </li>
              <li className="my-4 leading-normal">
                Note that some filters may not affect certain data points. For instance, TBHs are based most granularly on the job requisition city and not the office location.
              </li>
              <li className="my-4 leading-normal">
                For external data, use Weightings to determine how important each applicable factor is to the location’s rank
              </li>
              <li className="my-4 leading-normal">
                Use the Insights Genie to generate a custom presentation with screenshots and narratives included. Remember to deselect locations on the External page if you don’t want them included in the presentation
              </li>
            </ol>
          </Grid>
        </Grid>
      </Container>

      <Box bgcolor="#F6F6F6" mb={12}>
        <Container maxWidth="lg">
          <Grid container display="flex" columnSpacing={8}>
            <Grid item md={5}>
              <img src={question} alt="question-image" className="w-[360px] h-[360px]" />
            </Grid>
            <Grid item md={7}>
              <Typography variant="h2" gutterBottom mt={8}>
                Have more questions?
              </Typography>
              <Box mt={4}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  endIcon={<ArrowForwardIcon />} 
                  fullWidth 
                  sx={{ 
                    justifyContent: "space-between", 
                    bgcolor: "#FF9061", 
                    color: "white",
                    "&:hover": {
                      bgcolor: "#ff9061c7",
                    },
                    textTransform: "none"
                  }}
                  onClick={handleGoToFAQs}
                >
                  See our FAQs
                </Button>
              </Box>
              <Box mt={4}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  size="large" 
                  endIcon={<ArrowForwardIcon />} 
                  fullWidth 
                  sx={{ 
                    justifyContent: "space-between", 
                    bgcolor: "#AEBBFF", 
                    color: "black",
                    "&:hover": {
                      bgcolor: "#aebbffcc",
                    },
                    textTransform: "none"
                  }}
                  onClick={handleEmail}
                >
                  Email the People Insights Team
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </Box>
  );
};

export default LocationInsightsOverviewPage;
