import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const decisionTreeApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getDecisionTree: builder.query({
      query: () => ({
        url: "/decision-trees",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
    }),
  }),
});

export const decisionTreeSlice = createSlice({
  name: "decisionTree",
  initialState,
});

export const { 
  useGetDecisionTreeQuery,
} = decisionTreeApi;
