import React, { useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useGetAllPersonasQuery, usePostPersonaMutation, useUpdatePersonaMutation } from "store/slices/personaSlice";

import ExpandableList from "./ExpandableList/ExpandableList";
import PersonaEditor from "./PersonaEditor";
import PersonaFilter from "./PersonaFilter";

const PersonaManagement = () => {
  const [open, setOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  const { data: personas, isLoading } = useGetAllPersonasQuery();
  const [createPersona] = usePostPersonaMutation();
  const [updatePersona] = useUpdatePersonaMutation();

  const searchedPersonas = personas?.personas
    ? personas.personas.filter(persona => (persona.persona_name || "").toLowerCase().includes(searchKey.toLowerCase()))
    : [];

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (row) => {
    setSelectedPersona(row);
    setOpen(true);
  };

  const handleCreateNew = () => {
    setSelectedPersona(null);
    setOpen(true);
  };

  const handleCreate = async (values) => {
    return await createPersona(values).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updatePersona(values).unwrap();
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      renderCell: ({ row }) => (
        <>
          <IconButton onClick={() => handleEdit(row)}>
            <EditOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { field: "persona_name", headerName: "Persona", width: 180 },
    { field: "display_name", headerName: "Display Name", width: 160 },
    {
      field: "last_modified_on",
      headerName: "Last Updated",
      width: 140,
      valueGetter: (params) => dayjs(params.row.last_modified_on).format("MM/DD/YYYY")
    },
    {
      field: "dashboards",
      headerName: "Dashboards",
      width: 230,
      renderCell:({ row }) => row.persona_permissions?.dashboards ? (
        <ExpandableList items={row.persona_permissions.dashboards} />
      ) : null
    },
    {
      field: "research",
      headerName: "Researches",
      width: 230,
      renderCell:({ row }) => row.persona_permissions?.research ? (
        <ExpandableList items={row.persona_permissions.research} />
      ) : null
    },
    {
      field: "blog",
      headerName: "Blogs",
      width: 230,
      renderCell:({ row }) => row.persona_permissions?.blogs ? (
        <ExpandableList items={row.persona_permissions.blogs} />
      ) : null
    },
    {
      field: "employee_listening",
      headerName: "Employee Listening",
      width: 230,
      renderCell:({ row }) => row.persona_permissions?.emp_listening ? (
        <ExpandableList items={row.persona_permissions.emp_listening
        } />
      ) : null
    },
  ];

  return (
    <>
      <PersonaFilter 
        onCreateNew={handleCreateNew}
        onSearch={handleSearch}
        searchKey={searchKey}
      />
      <Box sx={{ height: 800 }}>
        <DataGrid
          rows={searchedPersonas}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          loading={isLoading}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          getRowId={(row) => row.persona_name}
          sx={{
            "& .MuiDataGrid-cell": {
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline"
            }
          }}
        />
      </Box>
      <PersonaEditor 
        open={open} 
        onClose={handleClose} 
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        persona={selectedPersona}
        availableTreeValues={personas?.portal_items || []}
      />
    </>
  );
};

export default PersonaManagement;
