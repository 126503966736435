import React from "react";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const UserFilter = ({ onCreateNew, onSearch, searchKey }) => {
  return (
    <Box mb={3} mt={2} display="flex" justifyContent="space-between">
      <TextField
        id="outlined-start-adornment"
        size="small"
        placeholder="Search"
        sx={{ mr: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        onChange={e => onSearch(e.target.value)}
        value={searchKey}
      />
      <Button
        onClick={onCreateNew}
        variant="contained"
        size="small"
        startIcon={<PersonAddIcon />}
      >
        Create New
      </Button>
    </Box>
  );
};

export default UserFilter;
