import React, { useState } from "react";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useGetAllUsersQuery, usePostUserMutation, useUpdateUserMutation } from "store/slices/userSlice";

import ExpandableList from "./ExpandableList/ExpandableList";
import UserEditor from "./UserEditor";
import UserFilter from "./UserFilter";

const UserManagement = () => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  const { data: users, isLoading } = useGetAllUsersQuery();
  const [createUser] = usePostUserMutation();
  const [updateUser] = useUpdateUserMutation();

  const searchedUsers = users?.users 
    ? users.users.filter(user => (user.name || "").toLowerCase().includes(searchKey.toLowerCase()))
    : [];

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (row) => {
    setSelectedUser(row);
    setOpen(true);
  };

  const handleCreateNew = () => {
    setSelectedUser(null);
    setOpen(true);
  };

  const handleCreate = async (values) => {
    return await createUser(values).unwrap();
  };

  const handleUpdate = async (values) => {
    return await updateUser(values).unwrap();
  };

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      renderCell: ({ row }) => (
        <>
          <IconButton onClick={() => handleEdit(row)}>
            <EditOutlinedIcon />
          </IconButton>
        </>
      ),
    },
    { 
      field: "name", 
      headerName: "User", 
      width: 160,
      renderCell: ({ row }) => (
        <Box display="flex">
          {row.name}
          {!row.is_active && (
            <Tooltip arrow title="User is inactive">
              <PersonOffIcon color="error" sx={{ ml: 1 }} />
            </Tooltip>
          )}
        </Box>
      )
    },
    { 
      field: "is_admin", 
      headerName: "Admin", 
      width: 120,
      renderCell: ({ row }) => row.is_admin ? "Admin" : "Regular User"
    },
    { 
      field: "personas", 
      headerName: "Persona", 
      width: 160,
    },
    {
      field: "last_modified_on",
      headerName: "Last Updated",
      width: 120,
      valueGetter: (params) => dayjs(params.row.last_modified_on).format("MM/DD/YYYY")
    },
    {
      field: "dashboards",
      headerName: "Dashboards",
      width: 300,
      cellClassName: "list",
      renderCell:({ row }) => (
        <ExpandableList personaPermissions={row.persona_permissions?.dashboards || []} overridePermissions={row.override_permissions?.dashboards || []} />
      )
    },
    {
      field: "research",
      headerName: "Researches",
      width: 300,
      renderCell:({ row }) => (
        <ExpandableList personaPermissions={row.persona_permissions?.research || []} overridePermissions={row.override_permissions?.research || []} />
      )
    },
    {
      field: "blog",
      headerName: "Blogs",
      width: 300,
      renderCell:({ row }) => (
        <ExpandableList personaPermissions={row.persona_permissions?.blogs || []} overridePermissions={row.override_permissions?.blogs || []} />
      )
    }, 
    {
      field: "employee_listening",
      headerName: "Employee Listening",
      width: 300,
      renderCell:({ row }) => <ExpandableList personaPermissions={row.persona_permissions?.["employee listening"] || []} overridePermissions={row.override_permissions?.["employee listeningZ"] || []} />
    },
  ];

  return (
    <>
      <UserFilter 
        onCreateNew={handleCreateNew}
        onSearch={handleSearch}
        searchKey={searchKey}
      />
      <Box sx={{ height: 800 }}>
        <DataGrid
          rows={searchedUsers}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          loading={isLoading}
          pageSizeOptions={[5, 10, 20]}
          disableRowSelectionOnClick
          getRowId={(row) => row.email_address}
          sx={{
            "& .MuiDataGrid-cell": {
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "baseline"
            }
          }}
        />
      </Box>
      <UserEditor 
        open={open} 
        onClose={handleClose} 
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        user={selectedUser}
        availableTreeValues={users?.portal_items || []}
      />
    </>
  );
};

export default UserManagement;
