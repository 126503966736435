import React, { useEffect } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid, Typography } from "@mui/material";
// import GroupedPieChart from "components/LocationInsights/GroupedPieChart";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import HeadcountFlexElection from "./HeadcountFlexElection";
import HeadcountTypeBreakdown from "./HeadcountTypeBreakdown";
import OtherLocations from "./OtherLocations";
import TeamLevelHeadcount from "./TeamLevelHeadcount";
import Question from "../../../components/Question";

const HeadcountDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const headCountSummaryData = data?.metrics?.find((item) => item.metric_name === "headcount_summary");
  const headcountTypeBreakdownData = data?.metrics?.find((item) => item.metric_name === "headcount_worker_sub_type");
  const headcountFlexElectionData = data?.metrics?.find((item) => item.metric_name === "headcount_flex_election");
  // const jobFunctionData = data?.metrics?.find((item) => item.metric_name === "headcount_job_function_distribution");
  const autodeskHeadcountData = data?.adsk_location_metrics?.find((item) => item.metric_name === "headcount_summary");
  // const autodeskJobFunctionData = data?.adsk_location_metrics?.find((item) => item.metric_name === "headcount_job_function_distribution");  
  const otherLocationsData = data?.metrics?.find((item) => item.metric_name === "other_locations_team");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <Box>
      <Question text="What is the headcount distribution for this and all teams at this location?" />
      <Box display="flex" mb={8} mt={2}>
        <InfoOutlinedIcon sx={{ mr: 1, color:"#353535", fontSize: 20 }} />
        <Box>
          <Typography variant="body1" color="#353535">
            All headcount numbers exclude workers in the Associates and Outside Service Providers subtypes unless indicated otherwise.
          </Typography>
          <Typography variant="body1" color="#353535">
            Team figures refer to the team at the location selected by filters, while Autodesk figures are for all teams at the selected location.
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          <TeamLevelHeadcount location={headCountSummaryData?.value} team={autodeskHeadcountData?.value} />
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <HeadcountTypeBreakdown data={headcountTypeBreakdownData?.value} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <HeadcountFlexElection data={headcountFlexElectionData?.value} />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} lg={5}>
          <Typography variant="h4" fontWeight="bold" mb={8} textAlign="center">
            Job Functions - Team
          </Typography>
          <HeadcountJobFunctionChart id="team" data={jobFunctionData?.value} />

          <Typography variant="h4" fontWeight="bold" mb={8} mt={4} textAlign="center">
            Job Functions - Autodesk
          </Typography>
          <HeadcountJobFunctionChart id="autodesk" data={autodeskJobFunctionData?.value} />
        </Grid> */}
        <Grid item xs={12} lg={12}>
          <OtherLocations data={otherLocationsData?.value} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeadcountDetail;

// const HeadcountJobFunctionChart = ({ data, id }) => {
//   if(!data) {
//     return (
//       <Typography variant="body1">No data available</Typography>
//     );
//   }
//   const chartData = Object.entries(data).map(([key, value]) => ({ label: key, value: +value }));
//   return (
//     <GroupedPieChart id={id} data={chartData} labelKey="label" valueKey="value" />
//   );
// };
