import React from "react";

const ShareIdea = () => {
  return (
    <>
      <div>
        <div className="hover:text-sky-800 cursor-pointer">
          <a href="mailto:pex.people.insights@autodesk.com?subject=Research%20Idea%20from%20People%20Insights%20Portal">
            Share Research Idea
          </a>
        </div>
      </div>
    </>
  );
};

export default ShareIdea;
