import React from "react";

import { useParams } from "react-router-dom";
import { useGetDashboardQuery } from "store/slices/dashboardSlice";

import DashboardDetailView from "./DashboardDetailView";

const DashboardDetailPage = () => {
  const { id } = useParams();
  const { data: dashboard, isLoading } = useGetDashboardQuery({ id: id }, { skip: !id });

  return (
    <DashboardDetailView 
      dashboard={dashboard} 
      isLoading={isLoading} 
    />
  );
};

export default DashboardDetailPage;
