import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";

const NAME_MAPPING = {
  dir_avg_tenure: "Dir",
  snr_dir_avg_tenure: "Snr Dir",
  vp_avg_tenure: "VP",
  svp_plus_avg_tenure: "SVP+"
};

const AverageTenure = ({ team, location }) => {
  if(!team && !location) {
    return; 
  }

  const data = Object.entries(NAME_MAPPING).map(([key, value]) => ({
    category: value,
    team: +team[key],
    location: +location[key]
  }));

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Average Tenure in Years
      </Typography>
      {!team && !location ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <AverageTenureChart data={data} />
      )}
    </Box>
  );
};

export default AverageTenure;

const AverageTenureChart = ({ data }) => {

  useLayoutEffect(() => {
    const root = am5.Root.new("average-tenure");

    root.setThemes([MyTheme.new(root)]);
    root._logo.dispose();

    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      paddingLeft:0,
      layout: root.verticalLayout
    }));


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: am5xy.AxisRendererY.new(root, {
        inversed: true,
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true
      })
    }));

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1,
        minGridDistance: 50,
      }),
      min: 0,
      extraMax: 0.05
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: field,
        categoryYField: "category",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
        }),
        // strokeOpacity: 1,
        // fillOpacity: 0.3
      }));

      series.columns.template.setAll({
        height: am5.p100,
        // strokeOpacity: 1,
        // fillOpacity: 0.3,
        width: am5.percent(10),
        maxHeight: 50,
      });


      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationX: 1,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true,
            fontSize: 12
          }),
        });
      });

      series.data.setAll(data);
      series.appear();

      return series;
    }

    createSeries("team", "Autodesk");
    createSeries("location", "Team");


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(chart.series.values);


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomY"
    }));
    cursor.lineY.set("forceHidden", true);
    cursor.lineX.set("forceHidden", true);

    return () => {
      root.dispose();
    };
  }, [data]);

  return <div id="average-tenure" style={{ width: "100%", height: "500px" }}></div>;
};
