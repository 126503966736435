import React, { useEffect } from "react";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSearchParams } from "react-router-dom";

const DomainSelect = ({ domains }) => {
  const [selectedDomain, setSelectedDomain] = React.useState(domains[0]);
  const [currentParams, setCurrentParams] = useSearchParams();

  useEffect(() => {
    if (!selectedDomain) {
      currentParams.delete("domain");
    } else {
      currentParams.set("domain", selectedDomain);
    } 
    setCurrentParams(currentParams);
  }, [currentParams, selectedDomain, setCurrentParams]);

  const handleClickDomain = (domain) => {
    setSelectedDomain(prev => prev === domain ? "" : domain);
  };

  return (
    <Box mt={2}>
      <Typography variant="h6">Domain</Typography>
      <Box className="flex" gap={2}>
        {domains.map((domain) => (
          <FormControlLabel
            key={domain}
            control={
              <Checkbox
                checked={domain === selectedDomain}
                onChange={() => handleClickDomain(domain)}
                disabled={selectedDomain.length > 0 && domain !== selectedDomain}
              />
            }
            label={domain}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DomainSelect;
