import React from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { columns } from "./column";

const ExternalViewTable = ({ data }) => {
  const table = useMaterialReactTable({
    columns,
    data,
    enableExpandAll: false, 
    enableExpanding: false,
    filterFromLeafRows: true, 
    getSubRows: (row) => row.subrows, 
    initialState: { expanded: true }, 
    paginateExpandedRows: false, 
    muiTableBodyCellProps: {
      sx: {
        fontSize: "18px"
      }
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: "14px"
      }
    },
    paginationDisplayMode: "pages",
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined",
    },
  });

  return (
    <MaterialReactTable
      table={table} 
    />
  );
};

export default ExternalViewTable;
