import React, { useCallback, useEffect, useMemo, useState } from "react";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Typography, Box, Card, CardContent, Tab } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import CommentContent from "./CommentContent/CommentContent";
import OverallSummaryFilter from "./SearchFilter/OverallSummaryFilter";
import SearchFilter from "./SearchFilter/SearchFilter";
import SearchTopicFilter from "./SearchFilter/SearchTopicFilter";
import {
  clearComments,
  useGetCommentsByPageMutation,
  useSearchCommentMutation,
} from "../../../store/slices/commentSlice";

const getValues = (params, name) => {
  return params.get(name)?.split("_") || [];
};

const CommentPage = () => {
  const [currentParams, setCurrentParams] = useSearchParams();
  const [overallSummaryComments, setOverallSummaryComments] = useState([]); 
  const [isOverallSummaryLoading, setIsOverallSummaryLoading] = useState(false);

  const [searchComment, { isLoading: isSearchTopicLoading, isError }] = useSearchCommentMutation();
  const [getCommentsByPage] = useGetCommentsByPageMutation();

  const { enqueueSnackbar } = useSnackbar();
  const { data: searchTopicComments } = useSelector((state) => state.comment);
  const dispatch = useDispatch();
  const searchQuery = currentParams.get("search");

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === "1") {
      currentParams.delete("search");
      setCurrentParams(currentParams);   
    }
  };

  const {
    country,
    favorability,
    division,
    l2m,
    l3m,
    geo,
    mgmtLvl,
    dates,
    question
  } = useMemo(() => {
    return {
      country: getValues(currentParams, "country"),
      favorability: getValues(currentParams, "favorability"),
      division: getValues(currentParams, "division"),
      l2m: getValues(currentParams, "l2m"),
      l3m: getValues(currentParams, "l3m"),
      geo: getValues(currentParams, "geo"),
      mgmtLvl: getValues(currentParams, "mgmtLvl"),
      dates: getValues(currentParams, "dates"),
      question: getValues(currentParams, "question"),
    };
  }, [currentParams]);

  // const favoriteComments = favCom ? JSON.parse(favCom) : [];

  const handleSearchTopic = async () => {
    try {
      const response = await searchComment({ 
        text: searchQuery,
        // country,
        // favorability,
        // division,
        // l2m,
        // l3m,
        // geo,
        // mgmtLvl,
        // dates
      }).unwrap();
      if (response && Object.keys(response).length === 0) {
        enqueueSnackbar("You don't have a permission to this page.", { variant: "error" });
      }
    } catch (error) {
      console.error({ error });
    }
  };

  const handleOverallSummary = useCallback(async (last_evaluated_key, accumulatedComments = []) => {
    try {
      setIsOverallSummaryLoading(true);
      const response = await getCommentsByPage({ 
        dates,
        offset: 3000,
        ...(last_evaluated_key ? { last_evaluated_key } : {})
      }).unwrap();

      const newAccumulatedComments = accumulatedComments.concat(response.data);

      if (response.last_evaluated_key) {
        await handleOverallSummary(response.last_evaluated_key, newAccumulatedComments);
      } else {
        setOverallSummaryComments(newAccumulatedComments);
        // dispatch(updateComments(newAccumulatedComments));
        setIsOverallSummaryLoading(false);
      }
    } catch (error) {
      console.error({ error });
      setIsOverallSummaryLoading(false);
    }
  }, [dates, getCommentsByPage]);

  useEffect(() => { 
    if(value === "1" && dates) {
      dispatch(clearComments());
      handleOverallSummary("");
    }
  }, [value, dates]);

  useEffect(() => {
    dispatch(clearComments());
    if(value === "2") {
      if (searchQuery) {
        handleSearchTopic(searchQuery);
      } else {
        dispatch(clearComments());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value,
    searchQuery,
  ]);
  
  const comments = value === "1" ? overallSummaryComments : searchTopicComments;

  const filteredComments = useMemo(() => {
    return comments.filter(
      (item) =>
        (!country.length || country.includes(item.country)) &&
        (!favorability.length || favorability.includes(item.favorability)) &&
        (!dates.length || dates.includes(item.survey_date)) &&
        (!division.length || division.includes(item.division)) &&
        (!l2m.length || l2m.includes(item.l2_sup_org)) &&
        (!l3m.length || l3m.includes(item.l3_sup_org)) &&
        (!geo.length || geo.includes(item.geo)) &&
        (!mgmtLvl.length || mgmtLvl.includes(item.management_level)) &&
        (!question.length || question.includes(item.question)) 
    );
  }, [comments, country, favorability, dates, division, l2m, l3m, geo, mgmtLvl, question]);

  return (
    <>
      <Box mx={4}>
        <div className="text-center my-5">
          <Typography variant="h1">Employee Insights Survey Comment Analysis</Typography>
          <Typography variant="body1" fontWeight="bold" my={3}>
            Powered by People Insights Natural Language Processing Engine (NLP) & AutodeskGPT
          </Typography>
        </div>
        <Card>
          <CardContent sx={{ m: 2, pb: 2 }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", justifyContent: "center", display: "flex" }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Overall Summary" value="1" sx={{ textTransform: "none", fontWeight: "bold" }} />
                  <Tab label="Search Topic" value="2" sx={{ textTransform: "none", fontWeight: "bold" }} />
                </TabList>
              </Box>
              <TabPanel value="1">
                <OverallSummaryFilter comments={filteredComments} />
              </TabPanel>
              <TabPanel value="2">
                <SearchFilter 
                  comments={filteredComments} 
                  selectedFilters={{
                    country,
                    favorability,
                    division,
                    l2m,
                    l3m,
                    geo,
                    mgmtLvl,
                    dates,
                  }}
                  searchQuery={searchQuery}
                />
                <SearchTopicFilter comments={filteredComments} />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>

        {filteredComments && (
          <Typography variant="h2" mt={4} textAlign="center">
            {filteredComments.length} Comments Found
          </Typography>
        )}

        {value === "2" ? (
          searchQuery && <CommentContent isLoading={isSearchTopicLoading} filteredComments={filteredComments} isError={isError} isSearchTopic />
        ) : (
          <CommentContent isLoading={isOverallSummaryLoading} filteredComments={filteredComments} isError={isError} />
        )}
      </Box>
    </>
  );
};

export default CommentPage;
