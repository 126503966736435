import React from "react";

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Typography } from "@mui/material";

import FilterChips from "./FilterChips";

const Question = ({ text, filter }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <HelpOutlineOutlinedIcon sx={{ color: "#1598e9" }} />
        <Typography variant="h4" fontWeight="bold" ml={1} color="#1598e9">
          {text}
        </Typography>
      </Box>
      {!filter && (
        <FilterChips />
      )}
    </>
  );
};

export default Question;
