/* eslint-disable react/display-name */
import React from "react";

import { Box, Grid, Typography } from "@mui/material";

const ExpandedContent = React.forwardRef(({ themes }, ref) => {
  return (
    <Box px={4} py={6} ref={ref}>
      <Grid container spacing={4}>
        {Object.entries(themes["Themes"]).map(([title, description]) => (
          <Grid item sm={4} key={title}>
            <Box sx={{ bgcolor: "#ffff6f", height: "100%", p: 4, borderRadius: "4px" }}>
              <Typography variant="h5" fontWeight="bold" gutterBottom>{title}</Typography>
              <Typography variant="body1">{description}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default ExpandedContent;
