import { createApi } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import Cookies from "js-cookie";
import { enqueueSnackbar } from "notistack";

import { AUTH, BASE_URL } from "../constants/env";

// sending the cookie token along with the requests
const axiosInstance = axios.create({
  // withCredentials: false,
  baseURL: BASE_URL,
});

export { axiosInstance };

// New creation of redux toolkit query and going to use this further
const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
    async ({ url, method, data, params, headers = {}, preferredBaseUrl }) => {
      try {
        const result = await axios({
          url: preferredBaseUrl || baseUrl + url || "",
          method,
          data,
          params,
          headers: {
            "Content-Type": "text/plain; charset=UTF-8",
            ...headers,
          },
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError;
        if (err.response.status === 401) {
          Cookies.remove("storedAccessToken");
          Cookies.remove("code");
          // redirecting to the auth page when user is not authorized.
          window.location.href = AUTH;
        }

        // if(err.response.status === 500) {
        //   enqueueSnackbar("Internal server error!", { variant: "error" });
        // }
        if (err.response.status === 403) {
        //   window.location.href = `${REDIRECT_URL}/forbidden`;
          enqueueSnackbar("You don't have permission to this api", { variant: "error" });
        }
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export const insightApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: () => ({}),
});
