import React, { useLayoutEffect } from "react";


import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";
import dayjs from "dayjs";

const TeamForecastedHeadcount = ({ data }) => {
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Budgeted Headcount - Autodesk-Level at Location
      </Typography>
      {!data ? (
        <Typography variant="body1">No data available</Typography>
      ) : (
        <ForecastedHeadcountChart id="team-forecasted-headcount" data={data} />
      )}
    </Box>
  );
};

const getChartData = (data) => {
  const observed = Object.entries(data.observed).map(([key, value]) => ({ date: dayjs(key).toDate().getTime(), observed: +value })).sort((a, b) => a.date - b.date);
  const projection = Object.entries(data.projected).map(([key, value]) => ({ date: dayjs(key).toDate().getTime(), projection: +value })).sort((a, b) => a.date - b.date);
  return [
    ...observed,
    { ...observed.at(-1), projection: observed.at(-1).observed },
    ...projection
  ].sort((a, b) => a.date - b.date);
};

export default TeamForecastedHeadcount;

export const ForecastedHeadcountChart = ({ data, id }) => {

  const chartData = getChartData(data);

  // console.log({ chartData });
  // This code will only run one time
  useLayoutEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([MyTheme.new(root)]);
    root._logo.dispose();

    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      layout: root.verticalLayout,
      pinchZoomX:true
    }));

    chart.get("colors").set("step", 3);


    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);


    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      maxDeviation: 0.3,
      baseInterval: {
        timeUnit: "month",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 60
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      renderer: am5xy.AxisRendererY.new(root, {})
    }));


    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function createSeries(field, name, color, dashed) {
      let series = chart.series.push(am5xy.SmoothedXLineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: field,
        valueXField: "date",
        stroke: color,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          getFillFromSprite: false,
          labelText: "[bold]{name}[/]\n{valueX}: [bold]{valueY}[/]"
        })
      }));
  
      series.get("tooltip").get("background").setAll({
        fillOpacity: 0.7,
        fill: color,
        pointerBaseWidth: 0
      });
  
      series.strokes.template.setAll({
        strokeWidth: 2
      });
  
      if (dashed) {
        series.strokes.template.set("strokeDasharray", [5, 3]);
      }
  
      series.data.setAll(chartData);
      series.appear(1000);
  
      return series;
    }

    createSeries("observed", "Observed", am5.color(0xB1B106));
    // createSeries("easing", "Easing rules", am5.color(0xD68C45), true);
    // createSeries("stricter", "Stricter rules", am5.color(0x2C6E49), true);
    createSeries("projection", "Projection", am5.color(0xB1B106), true);


    // Set date fields
    // https://www.amcharts.com/docs/v5/concepts/data/#Parsing_dates
    root.dateFormatter.setAll({
      dateFormat: "yyyy-MM-dd",
      dateFields: ["valueX"]
    });


    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50
      })
    );

    legend.data.setAll(chart.series.values);

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box id={id} style={{ width: "100%", height: "400px" }}></Box>
  );
};
