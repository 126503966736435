import React from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

const CheckboxTreeView = ({ data, defaultValue, onChange, blockedIds }) => {
  const [selected, setSelected] = React.useState([...new Set([...blockedIds, ...defaultValue])]);

  const selectedSet = React.useMemo(() => new Set(selected), [selected]);

  const parentMap = React.useMemo(() => {
    return goThroughAllNodes(data);
  }, []);

  // Get all children from the current node.
  function getAllChild(
    childNode,
    collectedNodes = []
  ) {
    if (childNode === null) return collectedNodes;

    collectedNodes.push(childNode.id);

    if (Array.isArray(childNode.children)) {
      for (const node of childNode.children) {
        getAllChild(node, collectedNodes);
      }
    }

    return collectedNodes;
  }

  function goThroughAllNodes(
    nodes,
    map = {}
  ) {
    nodes.forEach((item) => {
      if (!item.children) {
        return;
      }
      map[item.id] = getAllChild(item).splice(1);
      for (let childNode of item.children) {
        goThroughAllNodes([childNode], map);
      }
    });

    return map;
  }

  const getChildById = (nodes, id) => {
    let array = [];
    let path = [];

    // recursive DFS
    function getNodeById(node, id, parentsPath) {
      let result = null;

      if (node.id === id) {
        return node;
      } else if (Array.isArray(node.children)) {
        for (let childNode of node.children) {
          result = getNodeById(childNode, id, parentsPath);

          if (result) {
            parentsPath.push(node.id);
            return result;
          }
        }

        return result;
      }

      return result;
    }

    let nodeToToggle = null;
    nodes.forEach((item) => {
      const result = getNodeById(item, id, path);
      if (result) {
        nodeToToggle = result;
        return;
      }
    });
    return { childNodesToToggle: getAllChild(nodeToToggle, array), path };
  };

  function getOnChange(checked, nodes) {
    const { childNodesToToggle, path } = getChildById(data, nodes.id);

    let array = checked
      ? [...selected, ...childNodesToToggle]
      : selected
        .filter((value) => !childNodesToToggle.includes(value))
        .filter((value) => !path.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);
    setSelected(array);
    onChange(array);
  }

  const renderTree = (nodes) => {
    const allSelectedChildren = parentMap[
      nodes.id
    ]?.every((childNodeId) => selectedSet.has(childNodeId));
    const checked = selectedSet.has(nodes.id) || allSelectedChildren || false;

    const indeterminate =
      parentMap[nodes.id]?.some((childNodeId) =>
        selectedSet.has(childNodeId)
      ) || false;

    const isBlockedParent = 
    parentMap[nodes.id]?.some((childNodeId) =>
      blockedIds.includes(childNodeId)
    ) || false;

    return (
      <TreeItem
        key={nodes.id}
        itemId={`${nodes.id}`}
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                disabled={isBlockedParent || blockedIds.includes(nodes.id)}
                indeterminate={!checked && indeterminate}
                onChange={(event) =>
                  getOnChange(event.currentTarget.checked, nodes)
                }
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={<>{nodes.title}</>}
            key={nodes.id}
          />
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return (
    <SimpleTreeView>
      {data.map((category) => renderTree(category))}
    </SimpleTreeView>
  );
};

export default CheckboxTreeView;
