import React, { useEffect } from "react";

import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetLocationInsightsQuery } from "store/slices/locationSlice";

import AttritionSummary from "./AttritionSummary";
import AttritionTrends from "./AttritionTrends";
import Question from "../../../components/Question";

const AttritionDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetLocationInsightsQuery();
  const { data, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  const attritionClassification = data?.metrics?.find((item) => item.metric_name === "attrition_classification");
  const attritionTrends = data?.metrics?.find((item) => item.metric_name === "attrition_rates");
  const locationAttritionRate = data?.metrics?.find((item) => item.metric_name === "attrition_year");
  const teamAttritionRate = data?.adsk_location_metrics?.find((item) => item.metric_name === "attrition_year");

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <Box>
      <Question text="What are the attrition patterns at this location?" />
      <Typography variant="h5" mt={8} mb={8}>Team Attrition Classification: <b>{attritionClassification?.value}</b></Typography>
      <Grid container spacing={4}>
        <Grid item lg={6} xs={12}>
          <AttritionSummary 
            locationAttrition={locationAttritionRate?.value} 
            teamAttrition={teamAttritionRate?.value}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <AttritionTrends 
            data={attritionTrends?.value}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AttritionDetail;
