import React, { useState, useEffect, useRef } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import { titleize } from "utils/slug";

const ResearchDetailView = ({ research }) => {
  const [isOpen, setIsOpen] = useState(false);

  const elementRef = useRef(null);
  const [elementHeight, setElementHeight] = useState(500);

  useEffect(() => {
    const renderData = (data) => {
      if (elementRef?.current) {
        return (
          <div className="text-gray-900">
            {(elementRef.current.innerHTML = data)}
          </div>
        );
      }
    };

    renderData(research?.content);
  }, [research]);

  function elementcheck() {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight;
      if (height > window.innerHeight) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
      setElementHeight(height);
    }
  }
  useEffect(() => {
    elementcheck();
    window.scrollTo(0, 0);
  }, [research]);

  if (!research) {
    return null;
  }

  return (
    <section>
      <nav
        className="text-black  ml-8 text-lg text-left font-bold my-8"
        aria-label="Breadcrumb"
      >
        <ol className="list-none p-0 inline-flex">
          <li className="flex items-center">
            <Link to={"/"}>Home</Link>
            <ChevronRightIcon />
          </li>
          <li className="flex items-center">
            <Link to={`/${window.location.href.split("/")[3]}`}>
              {titleize(window.location.href.split("/")[3])}
            </Link>
            <ChevronRightIcon />
          </li>
          <li>
            <Link to={"#"} className="text-gray-500" aria-current="page">
              {research?.title}
              {/* {window.location.href.split("/")[4].toString()} */}
            </Link>
          </li>
        </ol>
      </nav>
      <div className="max-w-screen-lg mx-auto">
        <main className="mt-10">
          <div className="mb-4 md:mb-0 w-full mx-auto relative">
            <div className="px-4 lg:px-0">
              <h2 className="text-4xl text-left font-semibold text-gray-800 leading-tight">
                {research?.title}
              </h2>
              <a
                href="#"
                className="py-2 text-green-700 font-medium flex justify-start mb-2"
              >
                {Array.isArray(research?.category)
                  ? research?.category.join(" & ")
                  : research?.category}
              </a>
            </div>

            <img
              src={research?.image}
              className="w-full object-cover lg:rounded"
              style={{ height: "28em" }}
            />
          </div>
          <div className="text-left pt-5">
            <p className="text-lg">{research?.description}</p>
          </div>
          <hr />
          <div className="flex flex-col ">
            <div className="px-4 lg:px-0  text-gray-700 text-lg leading-relaxed w-full ">
              <section>
                <div
                  className={`${!isOpen ? "general-content overflow-y-hidden  h-[500px] pb-6 text-lg text-left" : "general-content h-auto pb-6 text-lg text-left"} `}
                  ref={elementRef}
                ></div>
              </section>
            </div>

            {elementHeight > window.screen.height && !isOpen && (
              <div className="flex justify-center items-center pt-10 ">
                {" "}
                <div
                  onClick={() => setIsOpen(true)}
                  className="b mb-5 mx-auto h-14 w-64 flex justify-center items-center "
                >
                  <div className=" h-14 w-64 bg-gradient-to-br from-[#3B71FF] to-[#C764F7] items-center rounded-full shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out"></div>
                  <a className="text-center text-white font-semibold z-10 pointer-events-none">
                            Continue Reading
                  </a>
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    </section>
  );
};

export default ResearchDetailView;
