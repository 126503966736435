import React, { useMemo, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import { Box, Card, CardContent, Divider, FormControlLabel, IconButton, Pagination, Switch, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { saveAs } from "file-saver";
import { useDebounce } from "hooks/useDebounce";
import { useGetCommentFeedbackQuery } from "store/slices/commentSlice";
import { getCurrentPageData, getPageCount } from "utils/pagination";
import * as XLSX from "xlsx";

import Comment from "./Comment";

const ITEM_PER_PAGE = 8;

const FilteredComments = ({ filteredComments }) => {
  const [page, setPage] = useState(1);
  const [showFavorite, setShowFavorite] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const { data: favCom } = useGetCommentFeedbackQuery();
  const debouncedValue = useDebounce(searchKey);

  const favoriteComments = favCom ? JSON.parse(favCom) : [];
  const searchedComments = useMemo(() => filteredComments.filter(c => c.comment.includes(debouncedValue)), [debouncedValue, filteredComments]);

  const pageData = useMemo(
    () => getCurrentPageData(searchedComments.filter(c => c.comment.includes(debouncedValue)), ITEM_PER_PAGE, page), 
    [debouncedValue, searchedComments, page]
  );

  const handleChangePage = (_event, page) => {
    setPage(page);
  };

  const handleChange = () => {
    setShowFavorite((prev) => !prev);
  };

  const handleChangeSearch = (event) => { 
    setSearchKey(event.target.value);
  };

  const downloadExcel = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Add a worksheet to the workbook
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(filteredComments),
      "Sheet 1"
    );

    // Write the workbook to an array buffer
    const arrayBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], {
      type: "application/octet-stream",
    });

    // Use the FileSaver.js library to save the Blob as a file
    saveAs(blob, "data.xlsx");
  };

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h4" fontWeight="bold">
              <NotesOutlinedIcon /> Comments 
              {(!showFavorite && debouncedValue) && (
                `(${searchedComments.length})`
              )}
            </Typography>
            {!showFavorite && (
              <>
                <TextField size="small" variant="outlined" placeholder="Search comments" value={searchKey} onChange={handleChangeSearch} />
                <IconButton onClick={downloadExcel}>
                  <DownloadIcon />
                </IconButton>
              </>
            )}
          </Box>
          <FormControlLabel control={<Switch checked={showFavorite} onChange={handleChange} />} label="Favorite" />
        </Box>
        <Divider sx={{ mx: -2 }} />
        <Box pt={4}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {showFavorite ? (
                  favoriteComments.map((item, index) => (
                    <TableRow
                      key={`${item.comment_id}-${index}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Comment isFavorite comment={item} favorite={favoriteComments.map(c => c.comment_id).includes(item.comment_id)} />
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ))
                ) : (
                  pageData.map((item, index) => (
                    <TableRow
                      key={`${item.comment_id}-${index}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Comment comment={item} favorite={favoriteComments.map(c => c.comment_id).includes(item.comment_id)} />
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!showFavorite && (
            <Box mt={4} display="flex" justifyContent="center">
              <Pagination
                count={getPageCount(searchedComments.length, ITEM_PER_PAGE)} 
                variant="outlined" 
                shape="rounded" 
                onChange={handleChangePage}
              />
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default FilteredComments;
