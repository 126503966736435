import React, { useEffect } from "react";

import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useLazyGetExternalLocationInsightsQuery } from "store/slices/locationSlice";

import DemandTrend from "./DemandTrend";
import SupplyDemand from "./SupplyDemand";
import Question from "../../../components/Question";

const SupplyAndDemandDetail = () => {
  const [currentParams] = useSearchParams();
  const [getLocationDetail] = useLazyGetExternalLocationInsightsQuery();
  const { externalData, isLoading } = useSelector((state) => state.location);

  useEffect(() => {
    const params = Object.fromEntries(currentParams.entries());
    getLocationDetail({ params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentParams]);

  if(isLoading) {
    return (
      <Box className="flex items-center justify-center w-full">
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(!externalData || externalData.length !== 1) {
    return (
      <Box mt={20}>
        <Typography variant="body1" textAlign="center">No data available</Typography>
      </Box>
    );
  } 

  const data = externalData[0];

  return (
    <Box>
      <Question text="What is the talent volume at this location?" />
      <Grid container spacing={4} mt={2}>
        <Grid item lg={4}>
          <Box>
            <Typography variant="h4" fontWeight="bold" my={4}>
              Supply Demand Ratio
            </Typography>
            <Box textAlign="center" border="1px solid grey" py={4}>
              <GroupsIcon sx={{ fontSize: "48px", my: 2 }}/>
              <Typography variant="body1" fontWeight="bold" fontSize="32px">{data.supplyDemandRatio}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h4" fontWeight="bold" my={4}>
                Active Demand
            </Typography>
            <Box textAlign="center" border="1px solid grey" py={4}>
              <BusinessIcon sx={{ fontSize: "48px", my: 2 }}/>
              <Typography variant="body1" fontWeight="bold" fontSize="32px">{data.details_demand_active}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={8}>
          <SupplyDemand data={data?.details_demand_supply} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <DemandTrend data={data?.details_demand_trend} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupplyAndDemandDetail;
