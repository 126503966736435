import React from "react";

import BiotechIcon from "@mui/icons-material/Biotech";
import BiotechOutlinedIcon from "@mui/icons-material/BiotechOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Header from "components/Header";
import logo from "img/autodesk-logo.svg";
import backgroundImage from "img/banner-bg.jpg";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import Footer from "../components/Footer/Footer";

// layout for navigation
const PageLayout = ({ children }) => {
  const { pages } = useSelector((state) => state.currentUser);  
  const { pathname } = useLocation();

  const tabs = [
    ...(pages?.includes("dashboards") 
      ? [
        { 
          label: "Dashboard", 
          filledIcon: <DashboardIcon />, 
          outlinedIcon: <DashboardOutlinedIcon />,
          to: "/dashboard" 
        }
      ] : []),
    
    ...(pages?.includes("research")
      ? [
        {
          label: "Research",
          filledIcon: <BiotechIcon />,
          outlinedIcon: <BiotechOutlinedIcon />,
          to: "/research",
        },
      ]: []),
    
    ...(pages?.includes("blogs")
      ? [
        {
          label: "Blog",
          filledIcon: <StickyNote2Icon />,
          outlinedIcon: <StickyNote2OutlinedIcon />,
          to: "/blog",
        },
      ]: []),
    
    ...(pages?.includes("listening")
      ? [
        {
          label: "Employee Listening",
          filledIcon: <RecordVoiceOverIcon />,
          outlinedIcon: <RecordVoiceOverOutlinedIcon />,
          to: "/employee-listening",
        },
      ]: []),

    ...(pages?.includes("insights-genie")
      ? [
        {
          label: "Insights Genie",
          filledIcon: <TipsAndUpdatesIcon />,
          outlinedIcon: <TipsAndUpdatesOutlinedIcon />,
          to: "/insights-genie",
        },
      ]: []),
  
    ...(pages?.includes("admin")
      ? [
        {
          label: "Admin",
          filledIcon: <ManageAccountsIcon />,
          outlinedIcon: <ManageAccountsOutlinedIcon />,
          to: "/admin",
        },
      ]: []
    )
  ];

  return (
    <section id="home-page">
      <Box
        sx={{
          height: "400px",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#222222",
          color: "white",
        }}
      >
        <Header onHomepage={true} />
        <div className="hero-banner">
          <div className="container mt-[80px] flex flex-col items-center gap-5">
            <div className="flex  mt-[80px] justify-center ">
              <img className="" src={logo} alt="logo" srcSet="" />
            </div>
            <div>
              <h1 className="text-white">People Insights Portal</h1>
              <p style={{ color: "white" }}>
                An in-house purpose built tool that provides insights through
                dashboards, research, and more.
              </p>
            </div>
          </div>
        </div>
      </Box>

      <div className="page-content">
        <main className="container">
          <Box className="flex justify-center text-[20px] pt-20 pb-8">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="w-full flex justify-center">
              <Tabs aria-label="navigation tab" value={pathname.split("/")[1]}>
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    icon={pathname.split("/")[1] === tab.to.split("/")[1] ? tab.filledIcon : tab.outlinedIcon}
                    component={NavLink}
                    to={tab.to}
                    value={tab.to.split("/")[1]}
                    sx={{
                      width: "200px",
                      fontWeight: "bold",
                      "&.Mui-selected": {
                        backgroundColor: "#e6eaef"
                      },
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <Box>
            {children}
          </Box>
          {/* tiles map */}
        </main>
      </div>
      <Footer />

      <div id="popup1" className="overlay">
        <div className="popup">
          <h2>Dashboards Access</h2>
          <a className="close" href="/">
            &times;
          </a>
          <div className="content">
            Access to dashboards is managed based on your role. If you need
            access to a dashboard please{" "}
            <a href="mailto:pex.people.insights@autodesk.com?subject=Requesting%20Access%20to%20People%20Insights%20Portal">
              reach out to us
            </a>
            .
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageLayout;
