import React from "react";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Typography, Box, Tooltip, IconButton } from "@mui/material";


import styles from "./Tile.module.scss";

const Tile = ({
  dashboard,
  onClickEdit,
  onClickDelete
}) => (
  <Box>
    <img
      src={`${dashboard.imgSrc}`}
      alt=" random imgee"
      className="w-full h-[200px] object-cover object-center rounded-lg shadow-md"
    />

    <div className="relative px-4 -mt-16">
      <div className="bg-white p-4 h-[160px] rounded-lg shadow-lg">
        <Tooltip title={dashboard.title} arrow>
          <Typography
            className={styles.title}
            variant="h6"
          >
            {dashboard.title}
          </Typography>
        </Tooltip>
        <Box mt={1}>
          <Typography variant="body1" className={styles.description}>
            {dashboard.description}
          </Typography>
        </Box>
        <Box className="flex justify-between" mx={-1}>
          <IconButton onClick={() => onClickEdit(dashboard.id)} size="small">
            <EditOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => onClickDelete(dashboard.id)} size="small">
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </div>
    </div>
  </Box>
);

export default Tile;
