import React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Typography } from "@mui/material";
import backgroundImage from "img/banner-bg.jpg";

const fagqs = [
  {
    label: "General Questions",
    questions: [
      {
        question: "What is the purpose of this tool?",
        answer: "This tool is designed to provide insights on location based on our internal workforce data and external benchmarks. It helps leaders, PBPs and PCs understand their workforce distribution, and make informed decisions related to geographical strategy."
      },
      {
        question: "How does the tool work?",
        answer: "The tool takes in both internal workforce data and external benchmark data, processes and analyzes this information, and then presents insights in a clear, visual format. This can include data visualization maps, charts, and graphs."
      },
      {
        question: "What kind of insights can this tool provide?",
        answer: "This tool can provide a wide range of insights including, but not limited to, workforce location and timezone distribution, and external job market data including talent supply, demand, cost, and diversity."
      },
    ]
  },
  {
    label: "Technical Questions",
    questions: [
      {
        question: "What are the sources of data?",
        answer: "The data used by this tool comes from two main sources: internal workforce data from Workday, and external data from TalentNeuron."
      },
      {
        question: "How often is the tool updated?",
        answer: "Updates for external data are generally rolled out monthly but may occur more frequently if necessary. Internal data is updated weekly."
      },
    ]
  },
  {
    label: "Term Definitions",
    questions: [
      {
        question: "What is a Hub Location?",
        answer: "These are Autodesk offices with 150 or more regular employees."
      },
      {
        question: "What is an MSA or Metropolitan Statistical Area?",
        answer: "A metropolitan statistical area (MSA) is the formal definition of a region that consists of a city and surrounding communities that are linked by social and economic factors."
      },
    ]
  },
];

const LocationInsightsFAQs = () => {
  return (
    <Box>
      <Box
        sx={{
          height: "200px",
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
        }}
      >
        <Typography className="text-white" variant="h2">
          FAQs
        </Typography>
      </Box>
      <Box bgcolor="#f6f6f6">
        <Container maxWidth="lg" sx={{ pt:6, pb:12, minHeight: "calc(100vh - 588px)", px: 8 }}>
          {fagqs.map((faq, index) => (
            <Box key={index} sx={{ mt: 8, mb: 4 }}>
              <Typography variant="h3" sx={{ mb: 4 }} className="text-center">
                {faq.label}
              </Typography>
              {faq.questions.map((faq, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index + 1}-content`}
                    id={`panel${index + 1}-header`}
                  >
                    <Box display="flex" alignItems="center">
                      <HelpOutlineIcon sx={{ mr: 3, ml: 2 }} /> {faq.question}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body1" ml={8} mr={4} mb={4} mt={2}>
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))}
        </Container>
      </Box>
    </Box>
  );
};

export default LocationInsightsFAQs;

