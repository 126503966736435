import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";
import dayjs from "dayjs";

const convertData = (data, color) => {
  let result = [];

  Object.keys(data).forEach((timezone) => {
    const details = data[timezone];
    const category = timezone;

    // Regular work hours
    result.push({
      category: category,
      fromDate: details.work_hours.start,
      toDate: details.work_hours.end,
      total_headcount: details.total_headcount,
      regular_headcount: details.regular_headcount,
      employee_headcount: details.employee_headcount,
      total_minus_osp_assoc_headcount: details.total_minus_osp_assoc_headcount,
      main: true,
      columnSettings: {
        fill: color,
      }
    });

    // Pre work hours
    result.push({
      category: category,
      fromDate: details.extended_work_hours.pre_work_hours,
      toDate: details.work_hours.start,
      total_headcount: details.total_headcount,
      regular_headcount: details.regular_headcount,
      employee_headcount: details.employee_headcount,
      total_minus_osp_assoc_headcount: details.total_minus_osp_assoc_headcount,
      columnSettings: {
        fill: color,
        fillOpacity: 0.6
      }
    });

    // Post work hours
    result.push({
      category: category,
      fromDate: details.work_hours.end,
      toDate: details.extended_work_hours.post_work_hours,
      total_headcount: details.total_headcount,
      regular_headcount: details.regular_headcount,
      employee_headcount: details.employee_headcount,
      total_minus_osp_assoc_headcount: details.total_minus_osp_assoc_headcount,
      columnSettings: {
        fill: color,
        fillOpacity: 0.6
      }
    });
  });

  // Add entries for the next day
  // const nextDayEntries = addNextDayEntries(result);
  // result = result.concat(nextDayEntries);

  return result;
};

const getGlobalOverlapHours = (data) => {
  let globalPreWorkStart = null;
  let globalPostWorkEnd = null;

  Object.keys(data).forEach((timezone) => {
    const details = data[timezone];

    const preWorkStart = dayjs(details.extended_work_hours.pre_work_hours);
    const postWorkEnd = dayjs(details.extended_work_hours.post_work_hours);

    // Update global pre-work start time
    if (globalPreWorkStart === null || preWorkStart.isAfter(globalPreWorkStart)) {
      globalPreWorkStart = preWorkStart;
    }

    // Update global post-work end time
    if (globalPostWorkEnd === null || postWorkEnd.isBefore(globalPostWorkEnd)) {
      globalPostWorkEnd = postWorkEnd;
    }
  });

  // Check if there's an actual overlap
  if (globalPreWorkStart.isBefore(globalPostWorkEnd)) {
    return {
      fromDate: globalPreWorkStart.format("YYYY-MM-DD HH:mm:ss"),
      toDate: globalPostWorkEnd.format("YYYY-MM-DD HH:mm:ss")
    };
  } else {
    return null; // No overlapping hours
  }
};

const TimezoneDistribution = ({ data }) => {

  const overlap = getGlobalOverlapHours(data);

  useLayoutEffect(() => {
    const root = am5.Root.new("timezone-distribution");


    root.setThemes([MyTheme.new(root)]);
    root._logo.dispose();

    root.dateFormatter.setAll({
      dateFormat: "HH:mm",
      dateFields: ["valueX", "openValueX"]
    });

    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: false,
      wheelY: false,
      layout: root.verticalLayout,
      paddingLeft: 0,
      paddingBottom: 50
    }));


    chart.set("cursor", am5xy.XYCursor.new(root, {}));
    // cursor.lineY.set("visible", false);
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    // chart.children.push(am5.Legend.new(root, {
    //   centerX: am5.p50,
    //   x: am5.p50
    // }));
    const colors= chart.get("colors");
  
    
    // Data for the chart
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    const yRenderer = am5xy.AxisRendererY.new(root, {
      inversed: true,
      minorGridEnabled: true,
      minGridDistance: 10,
    });
    yRenderer.labels.template.setAll({
      fontSize: 14
    });
    
    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200
        })
      })
    );

    const tempData = Object.keys(data).sort((key1, key2) => Number(data[key2].total_minus_osp_assoc_headcount) - Number(data[key1].total_minus_osp_assoc_headcount));
    
    yAxis.data.setAll(tempData.map((item) => ({ category: item })));
    
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "minute", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {
          minorGridEnabled: true,
          minGridDistance: 90
        }),
        tooltip: am5.Tooltip.new(root, {
          themeTags: ["axis"],
          animationDuration: 200
        })
      })
    );
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      xAxis: xAxis,
      yAxis: yAxis,
      openValueXField: "fromDate",
      valueXField: "toDate",
      categoryYField: "category",
      sequencedInterpolation: true
    }));
        
    series.data.processor = am5.DataProcessor.new(root, {
      dateFields: ["fromDate", "toDate"],
      dateFormat: "yyyy-MM-dd HH:mm"
    });

    series.columns.template.setAll({
      templateField: "columnSettings",
      strokeOpacity: 0,
      tooltipText: 
        " Total Headcount: {total_headcount}\n Regular Headcount: {regular_headcount}\n Employee Headcount: {employee_headcount}\n Total Minus OSP Assoc Headcount: {total_minus_osp_assoc_headcount}",
    });
    if(overlap) {
      const rangeDataItem = xAxis.makeDataItem({
        value: dayjs(overlap.fromDate).toDate().getTime(),
        endValue: dayjs(overlap.toDate).toDate().getTime()
      });
      const range = series.createAxisRange(rangeDataItem);
  
      range.columns.template.setAll({
        fill: am5.color(0xff621f),
        stroke: am5.color(0xff621f),
        fillOpacity: 0.9,
      });
      
      rangeDataItem.get("axisFill").setAll({
        fill: am5.color(0xff621f),
        fillOpacity: 0.2,
        visible: true
      });
    } 

    const chartData = convertData(data, colors.getIndex(0));
    series.data.setAll(chartData);

    chart.plotContainer.children.push(am5.Label.new(root, {
      text: "Pacific Time",
      x: am5.percent(50),
      centerX: am5.percent(50),
      y: am5.percent(100),
      dy: 20,
      fontWeight: "bold"
    }));

    series.bullets.push(function(r, series, dataItem) {
      console.log(dataItem.dataContext);
      if (dataItem.dataContext.main) {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            text: "{total_minus_osp_assoc_headcount}",
            fill: am5.color(0xffffff),
            centerX: am5.percent(50),
            centerY: am5.percent(50),
            textAlign: "center",
            populateText: true
          })
        });
      } else {
        return null;
      }
    });

    return () => {
      root.dispose();
    };
  }, [data, overlap]);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={4} pr={2}>
        <Box display="flex">
          <InfoOutlinedIcon sx={{ mr: 1, color:"#353535", fontSize: 20 }} />
          <Box>
            <Typography variant="body1" color="#353535">
            These time zone overlaps may change due to Daylight Savings Time
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <AccessTimeOutlinedIcon sx={{ mr: 1, color:"#353535", fontSize: 20 }}/>
          {overlap 
            ? <Typography variant="body1" color="#353535" textAlign="right">The overlap hours are from <b>{dayjs(overlap.fromDate).format("h:mm A")}</b> to <b>{dayjs(overlap.toDate).format("h:mm A")} PT.</b> across all timezones.</Typography> 
            : <Typography variant="body1" color="#353535" textAlign="right">There are no overlapping hours across all timezones.</Typography>}
        </Box>
      </Box>
      <div id="timezone-distribution" style={{ width: "100%", height: `${Object.keys(data).length * 50+ 100}px` }}></div>
    </Box>
  );
};

export default TimezoneDistribution;
