import React from "react";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";
import { Controller } from "react-hook-form";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageUploadFormField = ({
  control,
  name,
  rules,
  error,
  ...props
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange } }) =>
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} {...props}>
            Image
            <VisuallyHiddenInput type="file" onChange={e => onChange(e.target.files[0])} />
          </Button>
        }
      />
      {error && (
        <FormHelperText error>{error.message}</FormHelperText>
      )}
    </>
  );
};

export default ImageUploadFormField;
