import React from "react";

import { Typography } from "@mui/material";
import LineBarChart from "components/LocationInsights/LineBarChart";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const FavorabilityOverTime = ({ data }) => {
  const [currentParams] = useSearchParams();
  const searchTerm = currentParams.get("search");
  const countsByDate = data?.reduce((acc, item) => {
    const dateKey = dayjs(item.survey_date).valueOf();
    if (!acc[dateKey]) {
      acc[dateKey] = { favorable: 0, neutral: 0, unfavorable: 0 };
    }

    const favorability = item.favorability.toLowerCase();
    if (favorability === "favorable") {
      acc[dateKey].favorable++;
    } else if (favorability === "neutral") {
      acc[dateKey].neutral++;
    } else if (favorability === "unfavorable") {
      acc[dateKey].unfavorable++;
    }
    return acc;
  }, {});

  const chartData = Object.keys(countsByDate).map((date) => {
    const total =
      countsByDate[date].favorable +
      countsByDate[date].neutral +
      countsByDate[date].unfavorable;
    const percentFavorable = ~~((countsByDate[date].favorable / total) * 100);
    return {
      date: Number(date),
      favorable: countsByDate[date].favorable,
      neutral: countsByDate[date].neutral,
      unfavorable: countsByDate[date].unfavorable,
      percentFavorable,
    };
  }).sort((a, b) => a.date - b.date);

  const x = chartData.length > 1 ? Math.round((chartData.at(-1).percentFavorable - chartData.at(-2).percentFavorable) / chartData.at(-2).percentFavorable * 100) : 0;

  return (
    <LocationInsightCard title="Favorability Over Time" data={chartData}>
      {x > 0 && (
        <Typography variant="body1" gutterBottom mb={2}>
        The graph below shows the trend of favorability of <b>{searchTerm}</b> over time. Since the last survey, favorability related to <b>{searchTerm}</b> has 
          <b>{x > 0 ? " increased": " decreased"}</b> by <b>{Math.abs(x)}%</b>.
        </Typography>
      )}
      <LineBarChart 
        id="search-term-result"
        data={chartData}
        categoryKey="date"
        height="500px"
        dataKeys={[
          {
            name: "Favorable",
            yKey: "favorable",
          },
          {
            name: "Neutral",
            yKey: "neutral",
          },
          {
            name: "Unfavorable",
            yKey: "unfavorable",
          },
        ]}
        lineValueKey="percentFavorable"
        lineValueLabel="Favorable"  
        showGrid={false}
        xLabel="Survey date"
        yLabel="Number of relevant comments"
      />
    </LocationInsightCard>
  );
};

export default FavorabilityOverTime;
