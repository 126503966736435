import React from "react";

import GroupsIcon from "@mui/icons-material/Groups";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SpeedIcon from "@mui/icons-material/Speed";
import { Box, Typography, Link } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";


export const Supply = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <LocationInsightCard title="Supply" data={data}>
      <Box textAlign="center">
        <GroupsIcon sx={{ fontSize: "48px", my: 2 }}/>
        <Typography variant="body1" fontWeight="bold" fontSize="32px">{data}</Typography>
      </Box>
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/external-view/detail/supply-and-demand?${currentParams.toString()}`}>
            View Supply Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export const SupplyDemandRatio = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <LocationInsightCard title="Supply Demand Ratio" data={data}>
      <Box textAlign="center">
        <SpeedIcon sx={{ fontSize: "48px", my: 2 }}/>
        <Typography variant="body1" fontWeight="bold" fontSize="32px">{data}</Typography>
      </Box>
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/external-view/detail/supply-and-demand?${currentParams.toString()}`}>
            View Supply Demand Ratio Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export const HiringDifficulty = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <LocationInsightCard title="Hiring Difficulty" data={data}>
      <Box textAlign="center">
        <PersonSearchIcon sx={{ fontSize: "48px", my: 2 }}/>
        <Typography variant="body1" fontWeight="bold" fontSize="32px" textTransform="capitalize">{data}</Typography>
      </Box>
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/external-view/detail/hiring-difficulty?${currentParams.toString()}`}>
            View Hiring Difficulty Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export const CompetitiveConcentration = ({ data }) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();
  return (
    <LocationInsightCard title="Competitive Concentration" data={data}>
      <Box textAlign="center">
        <ModeStandbyIcon sx={{ fontSize: "48px", my: 2 }}/>
        <Typography variant="body1" fontWeight="bold" fontSize="32px" textTransform="capitalize">{data}</Typography>
      </Box>
      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/external-view/detail/talent-competition?${currentParams.toString()}`}>
            View Competitive Concentration Details
        </Link>
      </Box>
    </LocationInsightCard>
  ); 
};
