import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
// import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Box, Typography } from "@mui/material";
import { MyTheme } from "components/LocationInsights/theme";

function convertStringNumbersToNumbers(obj) {
  if (Array.isArray(obj)) {
    return obj.map(convertStringNumbersToNumbers);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key] = convertStringNumbersToNumbers(value);
      return acc;
    }, {});
  } else if (typeof obj === "string" && !isNaN(obj)) {
    return Number(obj);
  }
  return obj;
}

const OtherLocations = ({ data }) => {
  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={4}>
        Team Headcount All Locations
      </Typography>
      {data ? (
        <OtherLocationsChart data={data} />
      ) : (
        <Typography variant="body1">No data available</Typography>
      )}
    </Box>
  );
};

export default OtherLocations;

const OtherLocationsChart = ({ data }) => {
  const chartData = data.map(item => convertStringNumbersToNumbers(item));

  // This code will only run one time
  useLayoutEffect(() => {
    
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new("other-locations");
    
    
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([
      MyTheme.new(root)
    ]);
    root._logo.dispose();
    
    // Create wrapper container
    let container = root.container.children.push(am5.Container.new(root, {
      width: am5.p100,
      height: am5.p100,
      layout: root.horizontalLayout
    }));
    
    
    // ==============================================
    // Column chart
    // ==============================================
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let columnChart = container.children.push(am5xy.XYChart.new(root, {
      width: am5.p50,
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 10,
      minorGridEnabled: true
    });
    let yAxis = columnChart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "category",
      renderer: yRenderer,
    }));
    
    yRenderer.grid.template.setAll({
      location: 1,
    });

    yRenderer.labels.template.setAll({
      fontSize: 14
    });
    
    let xAxis = columnChart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {
        strokeOpacity: 0.1
      })
    }));
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    let columnSeries = columnChart.series.push(am5xy.ColumnSeries.new(root, {
      name: name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "value",
      categoryYField: "category"
    }));
    
    columnSeries.columns.template.setAll({
      tooltipText: "{categoryY}: {valueX}",
      fill: am5.color(0x28eb42),
      stroke: am5.color(0x28eb42),
    });

    columnSeries.bullets.push(function() {
      return am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          text: "{valueX}",
          centerX: am5.percent(0),
          centerY: am5.percent(50),
          // textAlign: "center",
          populateText: true
        })
      });
    });

    columnSeries.data.setAll(chartData);
    yAxis.data.setAll(chartData);
    
    //series.data.setAll(data);
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    columnChart.appear(1000, 100);
    
    
    // ==============================================
    // Column chart
    // ==============================================
    
    let pieChart = container.children.push(
      am5percent.PieChart.new(root, {
        width: am5.p50,
        innerRadius: am5.percent(50)
      })
    );
    
    // Create series
    let pieSeries = pieChart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category"
      })
    );
    
    pieSeries.slices.template.setAll({
      templateField: "sliceSettings",
      strokeOpacity: 0
    });
    
    // let currentSlice;
    pieSeries.slices.template.events.on("click", function(ev) {
      const slice = ev.target;
      const active = slice.get("active");

      pieSeries.slices.each(function(slice) {
        if(slice != ev.target && slice.get("active")) {
          slice.set("active", false);
        }
        // slice.set("active", false);
      });

      if (active) {
        //   // If the slice is already active, reset to show all data
        //   columnSeries.data.setAll(chartData);
        //   yAxis.data.setAll(chartData);
        //   label1.set("text", "");
        //   label2.set("text", "");
        columnSeries.data.setAll(chartData);
        yAxis.data.setAll(chartData);
        columnSeries.columns.template.setAll({
          fill: am5.color(0x28eb42),
          stroke: am5.color(0x28eb42),
        });
      } else {
      //   // Activate the clicked slice and update the bar chart
        // slice.set("active", true);
        let color = slice.get("fill");
  
        label1.setAll({
          fill: color,
          text: root.numberFormatter.format(
            slice.dataItem.get("valuePercentTotal"),
            "#.#'%'"
          ),
        });
  
        label2.set("text", slice.dataItem.get("category"));
  
        columnSeries.columns.template.setAll({
          fill: slice.get("fill"),
          stroke: slice.get("fill"),
        });
  
        columnSeries.data.setAll(slice.dataItem.dataContext.breakdown);
        yAxis.data.setAll(slice.dataItem.dataContext.breakdown);
      }


      // if (currentSlice && currentSlice != slice && active) {
      //   currentSlice.set("active", false);
      // }
    
      // let color = slice.get("fill");
    
      // label1.setAll({
      //   fill: color,
      //   text: root.numberFormatter.format(slice.dataItem.get("valuePercentTotal"), "#.#'%'")
      // });
    
      // label2.set("text", slice.dataItem.get("category"));
    
      // columnSeries.columns.template.setAll({
      //   fill: slice.get("fill"),
      //   stroke: slice.get("fill")
      // });
    
      // columnSeries.data.setAll(slice.dataItem.dataContext.breakdown);
      // yAxis.data.setAll(slice.dataItem.dataContext.breakdown);
    
      // currentSlice = slice;
    });
    
    pieSeries.labels.template.set("forceHidden", true);
    pieSeries.ticks.template.set("forceHidden", true);
    
    pieSeries.data.setAll(chartData);
    
    // Add label
    let label1 = pieChart.seriesContainer.children.push(am5.Label.new(root, {
      text: "",
      fontSize: 35,
      fontweight: "bold",
      centerX: am5.p50,
      centerY: am5.p50
    }));
    
    let label2 = pieChart.seriesContainer.children.push(am5.Label.new(root, {
      text: "",
      fontSize: 12,
      centerX: am5.p50,
      centerY: am5.p50,
      dy: 30
    }));
    
    // // Pre-select first slice
    // pieSeries.events.on("datavalidated", function() {
    //   pieSeries.slices.getIndex(0).set("active", true);
    // });
    

    return () => {
      root.dispose();
    };
  }, [chartData]);

  return (
    <Box id="other-locations" style={{ width: "100%", height: "600px" }}></Box>
  );
};
