import React from "react";

import { Box, Card, Container, Typography } from "@mui/material";
import exampleImage from "img/decision-tree-example.jpg";

const GlintDecisionExample = () => {
  return (
    <Box height="calc(100vh - 60px)" backgroundColor="#f6f6f6" pb={4}>
      <Container maxWidth="xl" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Typography variant="h1" textAlign="center" py={4} fontWeight="bold">Example Decision Tree</Typography>
        <Card sx={{ flexGrow: 1 }}>
          <Box p={4}>
            <Typography variant="body1" mt={4} gutterBottom>
              The decision tree below predicts Autodesk employee connection scores based on key factors identified by the analysis. 
              These scores allow us to create an 'if-then' framework for understanding how the factors relate to specific employee connection levels.
            </Typography>
            <Typography variant="body1" mb={10}>
              For example, this tree tells us low belonging and low trust lead to low employee connection. 
              In contrast, high belonging, high decision-making, and high network ('I have a network of colleagues available to me when I need it') lead to higher values of employee connection. 
              Based on the underlying analysis, this tree also indicates how many employees fall into each level of employee connection.
            </Typography>
            <img src={exampleImage} alt="Decision Tree Example" style={{ width: "100%", height: "400px" }} />
            <Typography variant="body1" mt={10}>
              The interpretation of these predicted scores as 'low' or 'high' is somewhat subjective. Note that these scores are scaled to a 100-point system for easy reporting, but originally, they were derived from a 5-point Likert scale survey where responses ranged from 1 (Strongly Disagree) to 5 (Strongly Agree). When transformed, a rating of 5 becomes 100, while a rating of 1 becomes 0. In the current example, this means that a predicted score of 57 is closest to an original rating of a 3 on the survey. 
            </Typography>
          </Box>
        </Card>
      </Container>
    </Box>
  );
};

export default GlintDecisionExample;
