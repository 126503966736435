import React, { useEffect, useRef, useState } from "react";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Alert, Box, Card, CardContent, Divider, IconButton, Link, Tooltip, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { useSearchParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { useGenerateCommentsMutation } from "store/slices/commentSlice";

import styles from "./AIGeneratedComments.module.scss";
import CollapsedContent from "./CollapsedContent";
import ExpandedContent from "./ExpandedContent";

const getValues = (params, name) => {
  return params.get(name)?.split("_") || [];
};

const AIGeneratedComments = ({ filteredComments }) => {
  const [generateComments, { isLoading }] = useGenerateCommentsMutation();
  const [currentParams] = useSearchParams();
  const [isExpanded, setIsExpanded] = useState(false);
  const downloadRef = useRef(null);
  const [isServerError, setIsServerError] = useState(false); 
  
  const [themes, setThemes] = useState({ Themes: {} });

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (filteredComments?.length >= 10) {
        try {
          const country = getValues(currentParams, "country");
          const favorability = getValues(currentParams, "favorability");
          const division = getValues(currentParams, "division");
          const l2m = getValues(currentParams, "l2m");
          const l3m = getValues(currentParams, "l3m");
          const geo = getValues(currentParams, "geo");
          const mgmtLvl = getValues(currentParams, "mgmtLvl");
          const dates = getValues(currentParams, "dates");
          const searchQuery = currentParams.get("search");
          const question = getValues(currentParams, "question");

          const comments = await generateComments({ 
            comments: filteredComments.slice(0, 5000), 
            country,
            favorability,
            division,
            l2m,
            l3m,
            geo,
            mgmtLvl,
            dates,
            question,
            ...(searchQuery ? { text: searchQuery } : {}),
          }).unwrap();
          if (comments) {
            setThemes(comments);
          }
        } catch (error) {
          console.error({ error });
          if(error.status === 500) {
            setIsServerError(true);
          }
        }
      }
    }, 1_000);

    return () => clearTimeout(timer);
  }, [filteredComments, generateComments, currentParams]);

  const handleToggleFullScreen = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDownload = async () => {
    if(downloadRef.current) {
      const canvas = await html2canvas(downloadRef.current);
      const imgData = canvas.toDataURL("image/jpeg");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "autodesk_ai_generated_themes.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <Card className={`expandable-component ${isExpanded ? styles.expanded: ""}`}>
      <CardContent>
        <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <AutoAwesomeOutlinedIcon /> 
            <Box ml={2}>
              <Typography variant="h4" fontWeight="bold">
                AI Generated Themes
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Powered by AutodeskGPT
              </Typography>
            </Box>
          </Box>
          <Box>
            {isExpanded && (
              <Tooltip title="Download JPG" arrow>
                <IconButton onClick={handleDownload}>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Expand/Collapse View" arrow>
              <IconButton onClick={handleToggleFullScreen}>
                <OpenInFullIcon fontSize="10" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Divider sx={{ mx: -2 }} />
        <Box py={4}>
          {isLoading ? (
            <>
              <SyncLoader color="#5C5470" size={8} />
              <Typography variant="body1" color="grey" mt={4}>
                Please allow up to 30 seconds for the summary to load.
              </Typography>
            </>
          ) : (
            isServerError ?(
              <Alert severity="error" variant="outlined">
                <Typography variant="body1">
                  An error occurred while generating the AI generated themes. Please <Link sx={{ color: "blue", cursor: "pointer" }} onClick={handleReloadPage}>try again</Link>.
                </Typography>
              </Alert>
            ) : (
              isExpanded ? (
                <ExpandedContent themes={themes} ref={downloadRef} />
              ) : (
                <CollapsedContent themes={themes} />
              )
            )
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default React.memo(AIGeneratedComments);
