import React, { useState } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import CardListFilter from "components/CardListFilter/CardListFilter";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { useGetAllEmployeeListeningsQuery } from "store/slices/employeeListeningSlice";

import EmployeeInsightsPulse from "./EmployeeInsightsPulse";
import LifecycleSurveys from "./LifecycleSurveys";

const typeOptions = [
  {
    label: "Blog",
    value: "blog"
  },
  {
    label: "Research",
    value: "research"
  },
  {
    label: "Dashboard",
    value: "dashboard"
  },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const EmployeeListeningPage = () => {
  const [tab, setTab] = React.useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const { data = [], isLoading } = useGetAllEmployeeListeningsQuery();

  const searchedItems = data.filter(item => item.title.toLowerCase().includes(searchKey.toLowerCase()));
  const searchFilteredItems = dateFilter ? searchedItems.filter(item => dayjs(item.date, "YYYY-MM-DD").isAfter(dateFilter)) : searchedItems;
  const filterResult = typeFilter ? searchFilteredItems.filter(item => item.item_type === typeFilter) : searchFilteredItems;

  const handleSearch = (query) => {
    setSearchKey(query);
  };

  const handleDateFilterChange = (df) => {
    setDateFilter(df);
  };

  const handleChangeCategory = (type) => {
    setTypeFilter(type);
  };

  const handleClearFilter = () => {
    setDateFilter("");
    setTypeFilter("");
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if(isLoading) {
    return (
      <Box className="text-center" py={8}>
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  return (
    <Box maxWidth="1300px">

      <Box display="flex" justifyContent="center">
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>Employee Insights (Glint)</Typography>} {...a11yProps(0)} />
          <Tab label={<Typography variant="h5" fontWeight="bold" sx={{ textTransform: "none" }}>Lifecycle Surveys</Typography>} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Typography variant="body1" fontSize="14px" textAlign="center" mt={2}>
        Click on a&nbsp;
        <Link sx={{ cursor: "pointer" }} onClick={() => handleChange(null, tab === 1 ? 0 : 1)}>
          <b>{tab === 0 ? "Lifecycle Surveys" : "Employee Insights (Glint)"}</b>
        </Link>&nbsp;tab to see&nbsp;
        {tab === 0 ? "Lifecycle Surveys." : "Employee Insights (Glint)."}
      </Typography>

      <Box pt={4}>
        <CardListFilter
          searchKey={searchKey}
          onSearch={handleSearch}
          dateFilter={dateFilter}
          onDateFilterChange={handleDateFilterChange}
          filterValue={typeFilter}
          filterLabel="Type"
          filterList={typeOptions}
          onFilterChange={handleChangeCategory}
          onClearFilter={handleClearFilter}
        />
      </Box>

      <Box px={20} mt={8}>
        <CustomTabPanel value={tab} index={0}>
          <EmployeeInsightsPulse items={filterResult.filter( i => i.type === "pulse")} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} index={1}>
          <LifecycleSurveys items={filterResult.filter( i => i.type === "survey")} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default EmployeeListeningPage;
