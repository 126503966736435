import React from "react";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, Divider, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import Filters from "./Filters/Filters";

const getTabs = (basePath) => [
  {
    value: "headcount",
    label: "Headcount",
    path: `${basePath}/headcount`
  },
  {
    value: "attrition",
    label: "Attrition",
    path: `${basePath}/attrition`
  },
  {
    value: "timezone",
    label: "Timezone",
    path: `${basePath}/timezone`
  },
  {
    value: "leadership-depth",
    label: "Leadership Depth",
    path: `${basePath}/leadership-depth`
  },
  {
    value: "recruitment",
    label: "Open and Budgeted roles",
    path: `${basePath}/recruitment`
  },
  {
    value: "teams-based-at-location",
    label: "Teams Based at Location",
    path: `${basePath}/team-distribution`
  },
];

const InternalViewDetailLayout = () => {
  const { pathname } = useLocation();
  const [currentParams] = useSearchParams();
  const { id: dashboardId } = useParams();
  const navigate = useNavigate();
  const tabs = getTabs(`/dashboard/${dashboardId}/location-insights/internal-view/detail`);
  
  const handleBack = () => {
    navigate(`/dashboard/${dashboardId}/location-insights/internal-view?${currentParams.toString()}`);
  };

  return (
    <Box p={4} bgcolor="#F6F6F6">
      <Button sx={{ mb:4 }} size="small" onClick={handleBack} startIcon={<KeyboardBackspaceIcon />}>Back</Button>
      <Paper sx={{ p:4 }}>
        <Filters />
      </Paper>

      <Paper sx={{ px:4, py: 2, mt: 4 }}>
        <Tabs
          value={pathname}
        >
          {tabs.map((tab) => (
            <Tab 
              key={tab.value} 
              label={tab.label} 
              value={tab.path}
              to={`${tab.path}?${currentParams.toString()}`}
              component={NavLink}
            />
          ))}
        </Tabs>
        <Divider sx={{ mx: -4 }}/>
        <Box my={4}>
          <Outlet />
        </Box>
      </Paper>
    </Box>
  );
};

export default InternalViewDetailLayout;
