
import React from "react";

import { Box, Link } from "@mui/material";
import LocationInsightCard from "components/LocationInsights/LocationInsightCard";
import { useParams, useSearchParams, Link as RouterLink } from "react-router-dom";

import AttritionOverviewChart from "./AttritionOverviewChart";

const AttritionOverview = ({ 
  data, 
  // classificationData 
}) => {
  const [currentParams] = useSearchParams();
  const { id } = useParams();

  const chartDataObject = data?.value?.voluntary;
  const chartData = chartDataObject ? Object.entries(chartDataObject).map(([category, value]) => ({ category, value: Math.round(+value * 10) / 10 })).sort((a, b) => a.category.localeCompare(b.category)) : [];

  return (
    <LocationInsightCard data={chartDataObject} title="Voluntary Attrition">
      {/* <Typography variant="body1">
        Attrition Rate over the past 12 months
        Voluntary Attrition Rate at [Location] is <b>{classificationData?.value}</b>, with an average of 2% over the past year.
      </Typography> */}
      <AttritionOverviewChart data={chartData} />

      <Box mt={4}>
        <Link 
          component={RouterLink} 
          sx={{ cursor: "pointer", fontSize: "16px", fontWeight: "bold", color:"blue", mb: 2 }} 
          to={`/dashboard/${id}/location-insights/internal-view/detail/attrition?${currentParams.toString()}`}>
            View Attrition Details
        </Link>
      </Box>
    </LocationInsightCard>
  );
};

export default AttritionOverview;
