import React, { useEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5plugins_sliceGrouper from "@amcharts/amcharts5/plugins/sliceGrouper";

import { MyTheme } from "./theme";

const GroupedPieChart = ({ id, labelKey, valueKey, data }) => {
  useEffect(() => {
    // Create chart instance
    let root = am5.Root.new(id);  
    root._logo.dispose();
    if(data) {
      root.setThemes([
        MyTheme.new(root)
      ]);
      
      const chart = root.container.children.push( 
        am5percent.PieChart.new(root, {
          layout: root.verticalLayout,
          radius: am5.percent(100)
        }) 
      );
      
      // Create series
      const series = chart.series.push(
        am5percent.PieSeries.new(root, {
          valueField: valueKey,
          categoryField: labelKey
        })
      );

      series.slices.template.setAll({
        stroke: am5.color(0xffffff),
        strokeWidth: 2
      });
  
      series.labels.template.set("forceHidden", true);
      series.ticks.template.set("forceHidden", true);
      series.data.setAll(data);
      
      // Add legend
      const legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        centerY: am5.percent(100),
        x: am5.percent(50),
        y: am5.percent(110),
        layout: root.verticalLayout,
      }));

      legend.labels.template.setAll({
        fontSize: 12,
        fontWeight: "300"
      });
      legend.valueLabels.template.setAll({
        fontSize: 12,
        fontWeight: "700"
      });
      
      legend.data.setAll(series.dataItems);
      
      am5plugins_sliceGrouper.SliceGrouper.new(root, {
        series: series,
        legend: legend,
        clickBehavior: "break",
      });
    }

    return () => {
      root.dispose();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <div id={id} style={{ width: "100%", height: "300px" }}></div>;
};

export default GroupedPieChart;

