import React from "react";

import { Grid } from "@mui/material";

import JobFunctionFilter from "./JobFunctionFilter";
import LocationFilter from "./LocationFilter";
import ManagementFilter from "./ManagementFilter";

const Filters = () => {


  return (
    <Grid container spacing={4}>
      <Grid item xl={6}>
        <LocationFilter />
      </Grid>
      <Grid item xl={6}>
        <ManagementFilter />
      </Grid>
      <Grid item xs={12}>
        <JobFunctionFilter />
      </Grid>
    </Grid>
  );
};

export default Filters;
