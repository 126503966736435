import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { insightApi } from "../../apis/api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const blogApi = insightApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllBlogs: builder.query({
      query: () => ({
        url: "/blogTiles",
        params: {
          token: Cookies.get("storedAccessToken")
        },
      }),
      providesTags: ["Blogs"]
    }),
    getBlog: builder.query({
      query: ({ id }) => ({
        url: "/blogTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id
        },
      }),
    }),
    postBlog: builder.mutation({
      query: ({ ext, data }) => ({
        url: "/blogTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          ext
        },
        data,
        method: "POST"
      }),
      invalidatesTags: ["Blogs"]
    }),
    updateBlog: builder.mutation({
      query: ({ id, ext, data }) => ({
        url: "/blogTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
          ext
        },
        data,
        method: "PUT"
      }),
      invalidatesTags: ["Blogs"]
    }),
    deleteBlog: builder.mutation({
      query: ({ id }) => ({
        url: "/blogTiles",
        params: {
          token: Cookies.get("storedAccessToken"),
          id,
        },
        method: "DELETE"
      }),
      invalidatesTags: ["Blogs"]
    }),
  }),
});

export const blogSlice = createSlice({
  name: "user",
  initialState,
});

export const { 
  useUpdateBlogMutation, 
  usePostBlogMutation, 
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useGetBlogQuery,
  useLazyGetBlogQuery
} = blogApi;
