import React, { useEffect } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader"; 
import { titleize } from "utils/slug";

import CommentPage from "./CommentPage/CommentPage";
import InsightsGenie from "./InsightsGenie/InsightsGenie";
import Header from "../../components/Header";
import PowerBI from "../../components/PowerBI/PowerBI";
import TableauViz from "../../components/TableauViz";

const DashboardDetailView = ({ dashboard, isLoading, isEmployeeListening }) => {

  if (isLoading) {
    return (
      <Box
        height="calc(100vh - 300px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <BeatLoader color={"#5C5470"} />
      </Box>
    );
  }

  return (
    <Box bgcolor="#f6f6f6">
      {!isEmployeeListening && (
        <Header pageName={dashboard.pageName} />
      )}
      <Box backgroundColor="#f6f6f6" minHeight="calc(100vh - 56px)">
        {dashboard.val !== "glint-decision" && (
          <nav
            className="text-black ml-8 text-lg text-left font-bold py-8"
            aria-label="Breadcrumb"
          >
            <ol className="list-none p-0 inline-flex">
              <li className="flex items-center">
                <Link to={"/"}>Home</Link>
                <ChevronRightIcon />
              </li>
              <li className="flex items-center">
                <Link to={`/${window.location.href.split("/")[3]}`}>
                  {titleize(window.location.href.split("/")[3])}
                </Link>
                <ChevronRightIcon />
              </li>
              <li>
                <Link to={"#"} className="text-gray-500" aria-current="page">
                  {dashboard.title}
                </Link>
              </li>
            </ol>
          </nav>
        )}
        <DashboardContent dashboard={dashboard} />
      </Box>
    </Box>
  );
};

const DashboardContent = ({ dashboard }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboard.val === "location-insights") {
      navigate(`/dashboard/${dashboard.id}/location-insights`);
    } else if (dashboard.val === "glint-decision") {
      navigate(`/employee-listening/${dashboard.id}/decision-tree`);
    }
  }, [dashboard.id, dashboard.is_tableau, dashboard.val, navigate]);

  switch (dashboard.val) {
    case "comments":
      return <CommentPage />;
    case "insights-genie":
      return <InsightsGenie />;
    default:
      if (dashboard.is_tableau) {
        return <TableauViz val={dashboard.val} />;
      }
  }

  return <PowerBI val={dashboard.val} />;
};

export default DashboardDetailView;
