import React from "react";

import { Alert, AlertTitle, Box, Grid } from "@mui/material";
import { BeatLoader } from "react-spinners";

import Chart from "./Chart/Chart";
import CommentList from "./CommentList/CommentList";

const CommentContent = ({ filteredComments, isError, isSearchTopic, isLoading }) => {
  if(isLoading) {
    return (
      <Box className="text-center" py={8}>
        <BeatLoader color="#5C5470" />
      </Box>
    );
  }

  if(isError) {
    return (
      <Box mt={4}>
        <Alert severity="error">
          <AlertTitle sx={{ fontSize: "18px" }}>An error occurred while fetching the comments</AlertTitle>
          Please try again later.
        </Alert>
      </Box>
    );
  }
  if(filteredComments.length > 0 && filteredComments.length < 10) {
    return (
      <Box mt={4}>
        <Alert severity="warning"sx={{ border: "1px solid #ef7b2a" }}>
          <AlertTitle sx={{ fontSize: "18px" }}>Sorry, your query has less than 10 results.</AlertTitle>
        We do not display queries with less than 10 comments due to confidentiality. Please adjust your query and try again.
        </Alert>
      </Box>
    );
  }
  return (
    <Box py={4}>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12}>
          <Chart filteredComments={filteredComments} isSearchTopic={isSearchTopic} />
        </Grid>
        <Grid item xs={12} xl={12}>
          <CommentList filteredComments={filteredComments} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommentContent;
