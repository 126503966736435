import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Box, IconButton } from "@mui/material";
import CheckboxFormField from "components/FormComponents/CheckboxFormField";
import ImageUploadFormField from "components/FormComponents/ImageUploadFormField";
import RadioFormField from "components/FormComponents/RadioFormField";
import TextFormField from "components/FormComponents/TextFormField";
import { useForm } from "react-hook-form";
import { useLazyGetImageUploadUrlQuery, useUploadImageMutation } from "store/slices/researchSlice";

const DashboardEditor = ({ dashboard, onClose, open, onCreate, onUpdate }) => {
  const [getUploadImageUrl] = useLazyGetImageUploadUrlQuery();
  const [uploadImage] = useUploadImageMutation();
  const [isLoading, setIsLoading] = useState(false);

  const { handleSubmit, control, formState: { errors, isValid }, watch, setValue } = useForm({
    defaultValues: {
      title: "",
      description:  "",
      pageName: "",
      val: "",
      imgSrc: "",
      link: "",
      type: "powerBi",
      isComingSoon: false
    },
    mode: "all"
  });

  useEffect(() => {
    if(dashboard) {
      setValue("title", dashboard.title);
      setValue("description", dashboard.description);
      setValue("pageName", dashboard.pageName);
      setValue("val", dashboard.val);
      setValue("imgSrc", dashboard.imgSrc);
      setValue("link", dashboard.link);
      setValue("type", dashboard.is_tableau ? "tableau" : "powerBi");
      setValue("isComingSoon", dashboard.coming_soon);
    } else {
      setValue("title", "");
      setValue("description", "");
      setValue("pageName", "");
      setValue("val", "");
      setValue("imgSrc", "");
      setValue("link", "");
      setValue("type", "powerBi");
      setValue("isComingSoon", false);
    }
  }, [dashboard]);

  const img = watch("imgSrc");

  const bgImage = img 
    ? typeof img === "string" 
      ? img  
      : URL.createObjectURL(img)
    : undefined;

  const onSubmit = async (values) => {
    let uploadImgUrl = values.imgSrc;
    try {
      setIsLoading(true);
      if(bgImage && typeof values.imgSrc !== "string") {
        const url = await getUploadImageUrl().unwrap();
        await uploadImage({ data: values.imgSrc, url }).unwrap();
        uploadImgUrl = url.split("&")[0];
      }
      const { type, isComingSoon, ...otherValues } = values;
      if(dashboard) {
        await onUpdate({
          id: dashboard.id,
          data: {
            ...otherValues,
            imgSrc: uploadImgUrl,
            is_tableau: type === "tableau",
            coming_soon: isComingSoon
          }
        });
      } else {
        await onCreate({
          data: {
            ...otherValues,
            imgSrc: uploadImgUrl,
            is_tableau: type === "tableau",
            coming_soon: isComingSoon
          }
        });
      }
      onClose();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Dialog 
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Dashboard Editor</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box py={4}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextFormField
                  name="title"
                  label="Title"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.title}
                  rules={{
                    required: "Title is required"
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <Box display="flex" alignItems="center" gap={4} pl={8}>
                  <ImageUploadFormField
                    name="imgSrc"
                    control={control}
                    rules={{
                      required: "Image is required"
                    }}
                    error={errors.image}
                  />
                  <Box>
                    <img width={100} height={70} src={bgImage} />
                  </Box>
                </Box>
              </Grid>
              <Grid item md={12}>
                <TextFormField
                  name="description"
                  label="Description"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.description}
                  multiline
                  minRows={10}
                  rules={{
                    required: "Description is required"
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <RadioFormField
                  name="type"
                  label="Type"
                  control={control}
                  options={[
                    { label: "Power BI", value: "powerBi" },
                    { label: "Tableau", value: "tableau" },
                  ]}
                  isRow
                />
              </Grid>
              <Grid item md={12}>
                <TextFormField
                  name="pageName"
                  label="Page Name"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.pageName}
                  rules={{
                    required: "Page name is required"
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <TextFormField
                  name="link"
                  label="Tableau Server Url"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.link}
                  rules={{
                    required: "Tableau Server Url is required"
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <TextFormField
                  name="val"
                  label="Value"
                  type="text"
                  fullWidth
                  control={control}
                  error={errors.val}
                  rules={{
                    required: "Value is required"
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <CheckboxFormField 
                  name="isComingSoon"
                  label="Coming Soon"
                  fullWidth
                  control={control}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button type="button" onClick={onClose}>Cancel</Button>
          <LoadingButton color="primary" variant="contained" type="submit" disabled={!isValid} loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DashboardEditor;
