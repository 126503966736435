import React, { useEffect, useState } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useAddCommentFeedbackMutation } from "store/slices/commentSlice";

const getValues = (params, name) => {
  return params.get(name)?.split("_") || [];
};

const Comment = ({ comment, favorite, isFavorite }) => {
  const [currentParams] = useSearchParams();
  const [like, setLike] = useState(false);
  const [addComment] = useAddCommentFeedbackMutation();

  const geo = getValues(currentParams, "geo");
  const country = getValues(currentParams, "country");
  const search = currentParams.get("search") || "";

  useEffect(() => {
    setLike(favorite);
  }, [favorite]);

  const handleLike = () => {
    if(!like) {
      setLike(true);
      addComment({
        commentId: comment.comment_id,
        likeType: "liked",
        searchTerm: search,
        comment: comment.comment,
        question: comment.question,
        division: comment.division,
        geo: comment.geo,
        managementLevel: comment.management_level,
        surveyDate: comment.survey_date,
        favorability: comment.favorability,
      });
    } else {
      setLike(false);
      addComment({
        commentId: comment.comment_id,
        likeType: "unliked",
        searchTerm: search,
      });
    }
  };

  return (
    <Box mt={3} mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} mb={0.5}>
        {isFavorite ? (
          <Typography variant="h5" fontWeight="bold">
            {`Search Term - ${comment.search_term} | ${comment.question} | ${comment.division} | ${comment.geo} | ${dayjs(comment.survey_creation_date).format("MMM 'YY")} | ${comment.favorability}`} 
          </Typography>
        ) : (
          <Typography variant="h5" fontWeight="bold">
            {`${comment.question} | ${comment.division} ${(geo.length || country.length) ? "" : ` | ${comment.geo}`} | ${dayjs(comment.survey_date).format("MMM 'YY")} | ${comment.favorability}`}
          </Typography>
        )}
        {like ? (
          <IconButton onClick={handleLike}>
            <StarIcon fontSize="26px" color="warning" />
          </IconButton>
        ) : (
          <IconButton onClick={handleLike}>
            <StarBorderOutlinedIcon fontSize="26px" color="warning" />
          </IconButton>
        )}
      </Box>
      <Typography variant="body1">{comment.comment}</Typography>
    </Box>
  );
};

export default Comment;
