import React from "react";

import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";

const editor = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    [{ color: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};


const formats = [
  "header",
  "font",
  "size",
  "color",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const RichTextFormField = ({
  control,
  name,
  rules,
  error,
  label,
  ...props
}) => {
  const [checked, setChecked] = React.useState(false);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <InputLabel>{label}</InputLabel>
        <Box>
          <span>Code Editor</span>
          <Switch size="small" checked={checked} onChange={() => setChecked(prev => !prev)} />
        </Box>
      </Box>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) =>
          checked ? (
            <MarkdownEditor
              height="450px"
              {...field}
              enablePreview={false}
            />
          ) : (
            <ReactQuill
              theme="snow"
              modules={editor}
              formats={formats}
              bounds={".app"}
              {...field}
            />
          )
        }
        {...props}
      />
      {error && (
        <FormHelperText error>{error.message}</FormHelperText>
      )}
    </>
  );
};

export default RichTextFormField;
